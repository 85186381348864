import React, { useState, useEffect } from "react";
import styled, {keyframes} from "styled-components";
import { FaArrowUp, FaArrowDown, FaSearch } from "react-icons/fa";
import RequestEngine from "../../core/RequestEngine";

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

// Styled Loader
const Loader = styled.div`
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #21ad58;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: ${spin} 1s linear infinite;
    margin: 100px auto;
`;

// Main container for the activity screen
const ActivityContainer = styled.div`
  min-height: 100vh;
  padding: 20px;
  color: white;
  margin-top: 30px;
  font-family: "Inter", sans-serif;
`;

// Tabs and search container
const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

// Tabs container
const TabsContainer = styled.div`
    display: flex;
    gap: 10px;
`;

// Individual tab
const Tab = styled.button`
    background-color: ${(props) => (props.active ? "#21ad58" : "#0f211c")};
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;

    &:hover {
        background-color: ${(props) => (props.active ? "#1e964b" : "#193529")};
    }
`;

// Search input container
const SearchContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;

    svg {
        position: absolute;
        left: 10px;
        color: #d1d1d1;
    }

    input {
        padding: 10px 10px 10px 40px;
        border-radius: 8px;
        border: 1px solid #1f392e;
        background-color: #0f211c;
        color: white;
        font-size: 16px;
        width: 250px;

        &::placeholder {
            color: #d1d1d1;
        }

        &:focus {
            outline: none;
            border-color: #21ad58;
        }
    }
`;

// Table wrapper with rounded corners and padding
const TableWrapper = styled.div`
    background-color: #0f211c;
    border-radius: 12px;
    padding: 20px;
`;

// Styled table with custom headers and row styling
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;

  th {
    background-color: #00160f;
    color: #21ad58;
    font-weight: bold;
    padding: 12px;
    text-align: left;
    border-bottom: 2px solid #1f392e;
    cursor: pointer;
  }

  td {
    padding: 12px;
    border-bottom: 1px solid #1f392e;
    color: #d1d1d1;
    vertical-align: middle;
  }

  td.amount-negative {
    color: #e74c3c;
  }

  td.amount-positive {
    color: #2ecc71;
  }

  tr:hover {
    background-color: #193529;
    transition: background-color 0.2s ease;
  }
`;

// Title for the activity screen
const Title = styled.h2`
  color: white;
  font-weight: bold;
  margin-bottom: 20px;
`;

// Activity screen component
const ActivityScreen = () => {
    const [activityData, setActivityData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "ascending" });
    const [activeTab, setActiveTab] = useState("Unread");
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        let mounted = true;
        if (loading) return;

        const fetchData = async () => {
            setLoading(true);
            let engine = new RequestEngine();
            try {
                const response = await engine.getItem("user/me");
                if (response && response.status === 200 && response.data && mounted) {
                    setActivityData(response.data.data.userhistory);
                }
            } catch (error) {
                console.error("An error occurred while fetching activity data:", error);
            } finally {
                if (mounted) setLoading(false);
            }
        };

        fetchData();

        return () => {
            mounted = false;
        };
    }, []);

    const sortData = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        }

        const sortedData = [...activityData].sort((a, b) => {
            if (key === "amount") {
                return direction === "ascending" ? a[key] - b[key] : b[key] - a[key];
            } else if (key === "createdAt") {
                return direction === "ascending"
                    ? new Date(a[key]) - new Date(b[key])
                    : new Date(b[key]) - new Date(a[key]);
            } else {
                return direction === "ascending" ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
            }
        });

        setActivityData(sortedData);
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key !== key) return null;
        if (sortConfig.direction === "ascending") return <FaArrowUp />;
        return <FaArrowDown />;
    };

    // Filter data based on the active tab and search query
    const filteredData = activityData.filter((item) => {
        const matchesTab = activeTab === "Unread" ? !item.read : item.read;
        const matchesSearch = item.name.toLowerCase().includes(searchQuery.toLowerCase()) || item.transactiontype.toLowerCase().includes(searchQuery.toLowerCase());
        return matchesTab && matchesSearch;
    });

    if (loading) {
        return (
            <ActivityContainer>
                <Loader />
            </ActivityContainer>
        );
    }

    return (
        <ActivityContainer>
            <Title id={"title"}></Title>

            <HeaderContainer>
                <SearchContainer>
                    <FaSearch />
                    <input
                        type="text"
                        placeholder="Search by title..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </SearchContainer>
            </HeaderContainer>

            <TableWrapper>
                <StyledTable>
                    <thead>
                    <tr>
                        <th onClick={() => sortData("name")}>
                            Title {getSortIcon("name")}
                        </th>
                        <th onClick={() => sortData("createdAt")}>
                            Date {getSortIcon("createdAt")}
                        </th>
                        <th onClick={() => sortData("amount")}>
                            Amount {getSortIcon("amount")}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredData?.map((item, index) => (
                        <tr key={index}>
                            <td>
                                {item.name === "DEDUCT" || item.name==="Send" || item.transactiontype === "withdraw" ? (
                                    <>
                                        <FaArrowDown className="arrow-down" />{" "}
                                        <span style={{ color: "#e74c3c" }}>
                        {item.msg}
                      </span>
                                    </>
                                ) : (
                                    <>
                                        <FaArrowUp className="arrow-up" />{" "}
                                        <span style={{ color: "#2ecc71" }}>
                        {item.name} - {item.transactiontype}
                      </span>
                                    </>
                                )}
                            </td>
                            <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                            <td
                                className={item.amount < 0 ? "amount-negative" : "amount-positive"}
                            >
                                {item.amount.toFixed(2)}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </StyledTable>
            </TableWrapper>
        </ActivityContainer>
    );
};

export default ActivityScreen;
