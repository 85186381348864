import React, {useEffect} from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import {Memory} from "../../core/Memory";
import {Utilites} from "../../core/Utilites";
import RequestEngine from "../../core/RequestEngine";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: white;
    min-height: 60vh;
    text-align: center;
`;

const Title = styled.h1`
    font-size: 32px;
    margin-bottom: 20px;
`;

const Message = styled.p`
    font-size: 18px;
    margin-bottom: 40px;
`;

const Button = styled.button`
    background-color: #21ad58;
    color: white;
    padding: 12px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #21AD58;
    }
`;

const SuccessPayment = () => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/dashboard'); // Redirect to the dashboard or home
    };


    useEffect(() => {
        const fetchData = async () => {
            const engine = new RequestEngine();
            const response = await engine.getItem("user/me");
            if (response && response.status === 200 && response.data.success) {
                Memory.setItem('userinfo', JSON.stringify(response.data.data.userinfo));
            }
        };
        fetchData();

        Utilites.setTimeout(
            () => {
                window.location.reload();
            },
            1000
        )
    }, []);


    return (
        <Container>
            <Title>Payment Successful!</Title>
            <Message>Thank you for your payment. Your transaction was completed successfully.</Message>
            <Message>You will be redirected to your dashboard shortly.</Message>
            <Button onClick={handleRedirect}>Go to Dashboard</Button>
        </Container>
    );
};

export default SuccessPayment;
