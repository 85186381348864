import React, {useEffect, useState} from "react";
import styled, {keyframes} from "styled-components";
import { FaSort, FaArrowUp, FaArrowDown } from "react-icons/fa";
import RequestEngine from "../../core/RequestEngine";
import {Utilites} from "../../core/Utilites";
import videoIcon from "../../assets/images/video.png";
import user from "../../assets/images/user.png";
import questionIcon from "../../assets/images/questions.png";
import {Link} from "react-router-dom";

const ScreenContainer = styled.div`
    min-height: 100vh;
    padding: 20px;
    margin-top: 20px;
    font-family: "Inter", sans-serif;
    color: white;
`;

const Subtitle = styled.p`
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 30px;
    text-align: left;
`;

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const Loader = styled.div`
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #21ad58;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: ${spin} 1s linear infinite;
    margin: 100px auto;
`;

const CourseContainer = styled.div`
    background-color: #0a0f0d;
    min-height: 100vh;
    padding: 20px;
    font-family: "Inter", sans-serif;
    color: white;
    margin-top: 47px;
`;
const Section = styled.div`
    margin-bottom: 50px;
`;

const TableContainer = styled.div`
    background-color: #1f392e;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
`;

const SectionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    h3 {
        font-size: 18px;
        font-weight: bold;
        color: white;
    }

    .sort-button {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        background-color: #0f211c;
        border: 1px solid #21ad58;
        border-radius: 8px;
        padding: 5px 10px;
        color: white;
        cursor: pointer;

        &:hover {
            background-color: #21ad58;
        }
    }
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
`;

const TableRow = styled.tr`
    &:nth-child(even) {
        background-color: #2c2c2c;
    }

    &:hover {
        background-color: #333;
    }
`;

const TableHeader = styled.th`
    text-align: left;
    padding: 15px;
    font-size: 14px;
    background-color: #0f211c;
    color: white;
    border-bottom: 1px solid #333;
`;

const TableCell = styled.td`
    padding: 15px;
    font-size: 14px;
    color: #d1d1d1; 
     a{
         color: white;
         text-decoration: underline;
     }
`;

const Screen = () => {
    const [loading, setLoading] = useState(true); // Show loading spinner while fetching data
    const [customers, setCustomers] = useState([]);
    useEffect(() => {
        const fetchProgress = async () => {
            let engine = new RequestEngine();
            try {
                const response = await engine.getItem("user/videoprogress");
                if (response && response.status === 200 && response.data) {
                    setCustomers(response.data.data); // Store the fetched rank data
                } else {
                    console.error("Failed to fetch ranks:", response.data.message);
                }
            } catch (error) {
                console.error("An error occurred while fetching ranks:", error);
            } finally {
                setLoading(false); // Stop the loading spinner
            }
        };

        fetchProgress(); // Call the function to fetch rank data
    }, []);

    // Group notifications by date
    const groupedNotifications = customers.reduce((groups, notification) => {
        const date = Utilites.renderDate(notification.createdAt);
        if (!groups[date]) {
            groups[date] = [];
        }
        groups[date].push(notification);
        return groups;
    }, {});


    return (
        <ScreenContainer>
            <Subtitle>
                "Monitor and analyze user engagement for Customers and Members in real time."
            </Subtitle>
            {loading ? (
                <CourseContainer>
                    <Loader />
                </CourseContainer>
            ) : (
                <Section>
                    {/* Member Section */}
                    <SectionHeader>
                        <h3>Members</h3>
                    </SectionHeader>
                    {Object.keys(groupedNotifications).map((createdAt) => {
                        const members = groupedNotifications[createdAt].filter(
                            (notification) => notification.ismember
                        );
                        if (members.length === 0) return null;
                        return (
                            <div key={createdAt}>
                                <TableContainer>
                                    <Table>
                                        <thead>
                                        <tr>
                                            <TableHeader>Username</TableHeader>
                                            <TableHeader>Total Videos Watched</TableHeader>
                                            <TableHeader>Responses to Questions</TableHeader>
                                            <TableHeader>Preferred Language</TableHeader>
                                            <TableHeader>Date</TableHeader>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {members.map((notification) => (
                                            <TableRow key={notification.id}>
                                                <TableCell>
                                                    <b>
                                                        <Link to={`/engage/${notification.id}`}>
                                                            {notification.username}
                                                        </Link>
                                                    </b>
                                                </TableCell>
                                                <TableCell>{notification.totalvideo}</TableCell>
                                                <TableCell>{notification.totalquestion}</TableCell>
                                                <TableCell>ARABIC</TableCell>
                                                <TableCell>
                                                    {Utilites.renderDate(notification.createdAt)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        </tbody>
                                    </Table>
                                </TableContainer>
                            </div>
                        );
                    })}

                    {/* Customer Section */}
                    <SectionHeader>
                        <h3>Customers</h3>
                    </SectionHeader>
                    {Object.keys(groupedNotifications).map((createdAt) => {
                        const customers = groupedNotifications[createdAt].filter(
                            (notification) => !notification.ismember
                        );
                        if (customers.length === 0) return null;
                        return (
                            <div key={createdAt}>
                                <TableContainer>
                                    <Table>
                                        <thead>
                                        <tr>
                                            <TableHeader>Username</TableHeader>
                                            <TableHeader>Total Videos Watched</TableHeader>
                                            <TableHeader>Responses to Questions</TableHeader>
                                            <TableHeader>Preferred Language</TableHeader>
                                            <TableHeader>Date</TableHeader>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {customers.map((notification) => (
                                            <TableRow key={notification.id}>
                                                <TableCell>
                                                    <b>
                                                        <Link to={`/engage/${notification.id}`}>
                                                            {notification.username}
                                                        </Link>
                                                    </b>
                                                </TableCell>
                                                <TableCell>{notification.totalvideo}</TableCell>
                                                <TableCell>{notification.totalquestion}</TableCell>
                                                <TableCell>ARABIC</TableCell>
                                                <TableCell>
                                                    {Utilites.renderDate(notification.createdAt)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        </tbody>
                                    </Table>
                                </TableContainer>
                            </div>
                        );
                    })}
                </Section>
            )}
        </ScreenContainer>
    );
};

export default Screen;
