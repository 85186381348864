import React from "react";
import styled from "styled-components";
import logo from "../../assets/images/logo.svg";

const FooterContainer = styled.footer`
    padding-left: 15%;
    padding-right: 15%;
margin: 0 auto;
  background-color: #00160F;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
    padding-top: 20px;
`;

const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  gap: 20px;
`;

const LogoSection = styled.div`
  flex: 1;

  h1 {
    font-size: 24px;
    color: #00ffcc;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    color: #b3b3b3;
  }
`;

const DownloadButtons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;

  img {
    width: 120px;
    cursor: pointer;
  }
`;

const LinksSection = styled.div`
  display: flex;
  flex: 2;
  justify-content: space-evenly;

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin-bottom: 20px;
    font-size: 14px;
    color: #b3b3b3;
    cursor: pointer;

    &:hover {
      color: #00ffcc;
    }
  }

  h3 {
    font-size: 16px;
    color: #fff;
    margin-bottom: 20px;
  }
`;

const FooterBottom = styled.div`
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    color: #b3b3b3;

    a {
        color: #21AD58;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;


const SocialMediaIcons = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 15px;

  a {
    color: #21AD58;
    font-size: 24px;
    transition: color 0.3s;

    &:hover {
      color: #00e6b8;
    }
  }
`;


const CopyrightText = styled.div`

  color: #b3b3b3;
  font-size: 14px;
  text-align: center;
    padding-bottom: 20px;
    padding-top: 20px;

  span {
    color: #00ffcc;
    font-weight: bold;
  }
`;

const Footer = () => {
    return (
        <FooterContainer>
            {/* Top Section */}
            <FooterTop>
                {/* Logo and Info */}
                <LogoSection>
                        <img src={logo} width={200} alt="Multyncome" />
                    <p>
                        Welcome to Multyncome! We offer the best, most <br/> affordable products
                        and services around. Shop <br/>now and start finding great deals!
                    </p>
                    <DownloadButtons>
                    </DownloadButtons>
                </LogoSection>

                {/* Links Section */}
                <LinksSection>
                    <ul>
                        <h3>Quick Links</h3>
                        <li>About Us</li>
                        <li>Teams</li>
                        <li>Services</li>
                        <li>Features</li>
                    </ul>
                    <ul>
                        <h3>Support</h3>
                        <li>Terms & Conditions</li>
                        <li>Privacy Policy</li>
                        <li>FAQs</li>
                        <li>Support Center</li>
                    </ul>
                    <ul>
                        <h3>Company</h3>
                        <li>Careers</li>
                        <li>Updates</li>
                        <li>Job</li>
                        <li>Announce</li>
                    </ul>
                </LinksSection>
            </FooterTop>

            {/* Bottom Section */}
            {/* Bottom Section */}
            <FooterBottom>
                <SocialMediaIcons>
                    <a href="#" aria-label="Facebook">
                        <i className="fab fa-facebook"></i>
                    </a>
                    <a href="#" aria-label="LinkedIn">
                        <i className="fab fa-linkedin"></i>
                    </a>
                    <a href="#" aria-label="Instagram">
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a href="#" aria-label="YouTube">
                        <i className="fab fa-youtube"></i>
                    </a>
                    <a href="#" aria-label="Twitter">
                        <i className="fab fa-twitter"></i>
                    </a>
                </SocialMediaIcons>
                <CopyrightText>
                    Copyright © 2024
                </CopyrightText>
            </FooterBottom>
        </FooterContainer>
    );
};

export default Footer;
