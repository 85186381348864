import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import { Memory } from "../../core/Memory";
import { Utilites } from "../../core/Utilites";
import RequestEngine from "../../core/RequestEngine";
import {Link, useNavigate} from "react-router-dom"; // Assuming the request engine is already set up
import Fawallet from "../../assets/images/Vector.svg";
import send from "../../assets/images/sendicon.svg";
import request from "../../assets/images/request.svg";
import winner from  "../../assets/images/winner.svg"
import SectionUnderWinner from "./SectionUnderWinner";
import VideoPlayer from "./VideoPlayer";
import Playlist from "./Playlist";
import videoIcon from "../../assets/images/video.png";
import user from "../../assets/images/user.png";
import questionIcon from "../../assets/images/questions.png";
import {Pie, PieChart, ResponsiveContainer, Sector} from "recharts";

const PerformanceSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
  }
`;

const MetricSection = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 20px 10px;
  background: #0f211c;
  height: 90px;
  border-radius: 30px;
  align-items: center;
  justify-content: space-around;
  gap: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 15px;
    height: auto;
    width: 100%;
    gap: 20px;
  }
`;

const Metric = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: 1rem;
  text-align: center;

  .title {
    margin-bottom: 8px;
    font-size: 1rem;
  }
  
  .value {
    font-size: 1.5rem;
    font-weight: bold;
    color: ${(props) => props.color || "#21AD58"};
  }

  @media (max-width: 480px) {
    .title {
      font-size: 0.9rem;
    }
    .value {
      font-size: 1.2rem;
    }
  }
`;

const ButtonSection = styled.div`
  display: flex;
  background: #0f211c;
  border-radius: 30px;
  padding: 20px 10px;
  align-items: center;
  justify-content: space-evenly;
  flex-grow: 1;
  gap: 20px;
  height: 80px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 15px;
    height: auto;
    width: 100%;
    gap: 15px;
  }
`;

const ButtonIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  padding: 10px;
  border-radius: 12px;
  transition: background-color 0.3s ease, color 0.3s ease;

  .icon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;

    img {
      width: 47px;
      height: 47px;
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
        brightness(100%) contrast(100%);
      transition: filter 0.3s ease;
    }
  }

  &:hover {
    background-color: #21ad58;

    .icon img {
      filter: invert(50%) sepia(80%) saturate(600%) hue-rotate(110deg)
        brightness(100%) contrast(90%);
    }
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;

    .icon {
      width: 40px;
      height: 40px;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
`;


const Separator = styled.div`
  width: 1px;
  height: 80px;
  background-color: #ffffff;
  margin: 0 10px;

  @media (max-width: 768px) {
    width: 80%;
    height: 1px;
    margin: 10px 0;
  }
`;






const WinnerImage = styled.img`
  max-width: 80%; /* Limits width on smaller screens */
  height: auto;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const ModalContent = styled.div`
  background-color: #1c1c28;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  color: white;
  text-align: center;

  @media (max-width: 768px) {
    width: 90%;
    padding: 15px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  background-color: #1e1e1e;
  border: 1px solid ${({ error }) => (error ? "red" : "#333")};
  color: #fff;
  border-radius: 8px;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: ${({ error }) => (error ? "red" : "#21AD58")};
    background-color: #333;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #21ad58;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 15px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #1e8e4f;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;




const IconWithImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconImage = styled.img`
  width: 47px;
  height: 47px;
  margin-right: 8px;
`;

const CenteredImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;




// Styled components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;


const InputWrapper = styled.div`
  position: relative;
  width: 92%;
  margin-bottom: 15px;
`;


const ToggleButton = styled.span`
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
  color: #21ad58;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
`;


const ButtonLoader = styled.div`
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.8s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const CloseButton = styled(Button)`
  background-color: red;

  &:hover {
    background-color: darkred;
  }
`;


const VideoPlayerWithPlaylistContainer = styled.div`
    display: flex;
  margin-top: 20px;
    justify-content: center; /* Center the components horizontally */
    align-items: stretch; /* Ensure equal height for children */
    background-color: #0F211C;
    border-radius: 30px;
    gap: 0; /* Remove spacing between the components */
    @media (max-width: 768px) {
        flex-direction: column; /* Stack components vertically on smaller screens */
    }
`;

const VideoPlayerContainer = styled.div`
    display: flex;
    align-items: stretch; /* Ensure content fills the container */
    border-radius: 10px;
    overflow: hidden;
`;

const StatsHistoryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 20px;
  margin-top: 20px;
  gap: 20px;
  padding-left: 0;
  padding-right: 0;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
  }
`;

const StatCard = styled.div`
  flex: 1;
  background-color: #0F211C;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  margin-bottom: 20px;

  h2, h4 {
    color: white;
    text-align: center;
    margin: 0;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  h4 {
    font-size: 1rem;
    font-weight: normal;
    color: #A5A5A5;
  }

  @media (max-width: 768px) {
    padding: 15px;

    h2 {
      font-size: 1.2rem;
    }

    h4 {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 480px) {
    padding: 10px;

    h2 {
      font-size: 1rem;
    }

    h4 {
      font-size: 0.8rem;
    }
  }
`;

const ChartContainer = styled.div`
  width: 400px;
  height: 400px;

  @media (max-width: 768px) {
    width: 300px;
    height: 300px;
  }

  @media (max-width: 480px) {
    width: 200px;
    height: 200px;
  }
`;

const StatLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
   a{
     color: white;
     text-decoration: none;
   }

  .rank-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #21AD58;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .details {
    text-align: left;

    .title {
      font-size: 1.2rem;
      font-weight: bold;
      color: white;
    }

    .subtitle {
      font-size: 0.9rem;
      color: #A5A5A5;
      margin-top: 6px;
    }
  }
`;

const StatRight = styled.div`
  text-align: right;
  font-size: 12px;
  font-style: italic;
  color: #A5A5A5;
`;


const DropdownWrapper = styled.div`
  position: relative;
  width: 200px;
  margin: auto;
  margin-top: 30px;
`;

const DropdownButton = styled.button`
  background-color: #0f211c;
  color: #21ad58;
  border: 1px solid #21ad58;
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  width: 200px;
  text-align: left;
  position: relative;

  &:hover {
    background-color: #1e8e4f;
  }

  &::after {
    content: "▼"; /* Small arrow */
    font-size: 0.8rem;
    color: #21ad58;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%) ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0)")};
    transition: transform 0.3s ease;
  }
`;


const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 200px;
  background-color: #0f211c;
  border: 1px solid #21ad58;
  border-radius: 8px;
  z-index: 1000;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const DropdownItem = styled.div`
  padding: 10px;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #1e8e4f;
  }
`;

const StatsHistory = ({history,commitions}) => {

  const [selectedMonth, setSelectedMonth] = useState("January");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  if(!commitions || commitions==0){
    commitions=0.1
  }
  const data = [
    { name: 'Commission', value: commitions },
    { name: 'Profit', value: 0 },
  ];

  const months = ["January"];

  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = (_, index) => {
    setActiveIndex(index)
  };
  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
          <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
            {payload.name}
          </text>
          <Sector
              cx={cx}
              cy={cy}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              startAngle={startAngle}
              endAngle={endAngle}
              fill={fill}
          />
          <Sector
              cx={cx}
              cy={cy}
              startAngle={startAngle}
              endAngle={endAngle}
              innerRadius={outerRadius + 6}
              outerRadius={outerRadius + 10}
              fill={fill}
          />
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#fff"> {`${(payload.value==0.1?0:payload.value).toFixed(2)} $`}</text>
        </g>
    );
  };
  return (
      <StatsHistoryContainer>
        <StatCard>
          <ChartContainer>
            <ResponsiveContainer width="100%" height="100%">
              <div>
                <h2>{selectedMonth} - Commission</h2>
                <h4>from 01/01/2025 to 31/01/2025</h4>

                <DropdownWrapper>
                  <DropdownButton onClick={() => setDropdownOpen(!dropdownOpen)}>
                    {selectedMonth}
                  </DropdownButton>
                  <DropdownMenu isOpen={dropdownOpen}>
                    {months.map((month, index) => (
                        <DropdownItem
                            key={index}
                            onClick={() => {
                              setSelectedMonth(month);
                              setDropdownOpen(false);
                            }}
                        >
                          {month}
                        </DropdownItem>
                    ))}
                  </DropdownMenu>
                </DropdownWrapper>
              </div>

                <PieChart>
                  <Pie
                      activeIndex={activeIndex}
                      activeShape={renderActiveShape}
                      data={data}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      fill="#21AD58"
                      dataKey="value"
                      onMouseEnter={onPieEnter}
                  />
                </PieChart>

            </ResponsiveContainer>
          </ChartContainer>
        </StatCard>

        <div>
          {history?.map(x=>{
            const msg= x.type=="video"?"watched video"+x.videoname+"/"+x.status:"answerd to question/"+x.status;
            return <StatCard>
              <StatLeft>
                <div className="rank-circle"><img
                    width={20}
                    src={x.type === "video" ? videoIcon : x.type === "user" ? user : questionIcon}
                /></div>
                <div className="details">
                  <div className="title">Recent Histroy</div>
                  <div className="subtitle"> <Link to={"/engage/"+x.user?.id}><b>{x.user?.username} </b> </Link> : {msg}</div>
                </div>
              </StatLeft>
              <StatRight>{Utilites.renderDateTime(x.createdAt)}</StatRight>
            </StatCard>
          })}
        </div>


      </StatsHistoryContainer>
  );
};
const TopBar = ({commitions,history,ismember,topRecruitment,topCoins, accountBalance, coins, topRank,currentCredits }) => {
  const [modalData, setModalData] = useState(null);
  const navigate = useNavigate();
  const [isMfaEnabled, setIsMfaEnabled] = useState(false);
  const [showMfaModal, setShowMfaModal] = useState(false);
  const [trc, setTrc] = useState("");
  const [otptitle, setOtptitle] = useState("Enter MFA Key");
  const [inputValues, setInputValues] = useState({
    "TRC20 Address": "", // Keep TRC20 Address pre-filled
  });
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState({});
  const [transactionData, setTransactionData] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false); // For password toggle
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchMfaStatus = async () => {
      const user = JSON.parse(Memory.getItem("userinfo"));
      setTrc(user.trc);
      setInputValues((prevValues) => ({
        ...prevValues,
        "TRC20 Address": user.trc, // Pre-fill TRC20 Address
      }));
      setIsMfaEnabled(user.mfa === true);
    };
    fetchMfaStatus();
  }, []);

  const handleInputChange = (e, field) => {
    let value = e.target.value;
    if (field === "Amount") {
      // Allow only numbers for the Amount field
      value = value.replace(/\D/g, "");
    }

    setInputValues({
      ...inputValues,
      [field]: value,
    });

    if (value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: false,
      }));
    }
  };

  const handleTopUpClick = () => {
    navigate("/topup");
  };

  const handleSendClick = () => {
    setOtp("");
    setModalData({
      title: "Send",
      fields: ["Username", "Password", "Amount"],
    });
    clearInputsExceptTRC();
  };

  const handleRequestClick = () => {
    setOtp("");
    setModalData({
      title: "withdraw",
      fields: ["Password", "Amount", "TRC20 Address"],
    });
    clearInputsExceptTRC();
  };

  const clearInputsExceptTRC = () => {
    setInputValues((prevValues) => ({
      "TRC20 Address": prevValues["TRC20 Address"], // Keep TRC20 Address intact
      Password: "",
      Amount: "",
      Username: "",
    }));
  };

  const handleSubmit = async () => {
    const requiredFields = modalData.fields;
    const newErrors = {};

    requiredFields.forEach((field) => {
      if (!inputValues[field]) {
        newErrors[field] = true;
      }
    });

    if (
      modalData.title != "Send" &&
      inputValues["Amount"] &&
      parseFloat(inputValues["Amount"]) < 100
    ) {
      newErrors["Amount"] = true;
      Utilites.showErrorMessage("The amount must be at least 100.");
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    if (isMfaEnabled) {
      setTransactionData({
        type: modalData.title.toLowerCase(),
        ...inputValues,
      });
      setModalData(null);
      setShowMfaModal(true);
      setOtptitle("Enter MFA Key");
    } else {
      const engine = new RequestEngine();
      engine.getItem("user/generateotp");
      Utilites.showSucessMessage("OTP sent to your email!");
      setTransactionData({
        type: modalData.title.toLowerCase(),
        ...inputValues,
      });
      setModalData(null);
      setShowMfaModal(true);
      setOtptitle("Enter OTP Key");
    }
  };
  const [selectedVideo, setSelectedVideo] = useState(0);


  const validateOtp = async () => {
    setLoading(true);
    try {
      const data = {
        otp: otp,
        method: "mfa",
        type: transactionData.type,
        username: transactionData.Username || "",
        amount: transactionData.Amount,
        password: transactionData.Password,
        trc: transactionData["TRC20 Address"],
      };

      const engine = new RequestEngine();
      const response = await engine.postItem("user/validateotp", data);

      if (response && response.status === 200 && response.data.success) {
        Utilites.showSucessMessage("Done!");
        setShowMfaModal(false);
      } else {
        Utilites.showErrorMessage(response.data.message);
      }
    } catch (error) {
      Utilites.showErrorMessage("An error occurred during OTP validation.");
    } finally {
      setLoading(false);
    }
  };

  const handleMfaSubmit = () => {
    if (!otp) {
      Utilites.showErrorMessage("Please enter the OTP.");
      return;
    }
    validateOtp();
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const closeModal = () => {
    setModalData(null);
    setShowMfaModal(false);
  };

  return (
    <div>
      <PerformanceSection>
        <MetricSection>
          <Metric>
            <div className="title">Credits</div>
            <div className="value">{currentCredits}</div>
          </Metric>
          <Metric>
            <div className="title">Coins</div>
            <div className="value">{coins}</div>
          </Metric>
          <Metric color="#21AD58">
            <div className="title">Balance</div>
            <div className="value">${accountBalance}</div>
          </Metric>
        </MetricSection>

        <ButtonSection>
          <ButtonIcon onClick={handleTopUpClick}>
            <IconWithImage>
              <IconImage src={Fawallet} alt="Wallet Icon" />
            </IconWithImage>
            <div>Top Up</div>
          </ButtonIcon>
          <Separator />
          <ButtonIcon onClick={handleSendClick}>
            <IconWithImage>
              <IconImage src={send} alt="Send Icon" />
            </IconWithImage>
            <div>Send</div>
          </ButtonIcon>
          <Separator />
          <ButtonIcon onClick={handleRequestClick}>
            <IconWithImage>
              <IconImage src={request} alt="Request Icon" />
            </IconWithImage>
            <div>Request</div>
          </ButtonIcon>
        </ButtonSection>
      </PerformanceSection>

      <VideoPlayerWithPlaylistContainer>
        <VideoPlayerContainer>
          <VideoPlayer onboarding={true} ismember={ismember}/>
        </VideoPlayerContainer>
        <Playlist
            onSelect={(index) => setSelectedVideo(index)}
            selectedVideo={selectedVideo}
        />
      </VideoPlayerWithPlaylistContainer>


      <StatsHistory history={history}  commitions={commitions}/>



      <CenteredImageWrapper>
      <WinnerImage src={winner} alt="winner" />

    </CenteredImageWrapper>
      <SectionUnderWinner topCoins={topCoins}  topRank={topRank} topRecruitment={topRecruitment}  />

      {modalData && (
        <ModalOverlay>
          <ModalContent>
            <h2>{modalData.title}</h2>
            {modalData.fields.map((field, index) => (
              <InputWrapper key={index}>
                <Input
                  type={
                    field === "Password" && !passwordVisible
                      ? "password"
                      : "text"
                  }
                  placeholder={field}
                  error={errors[field]}
                  value={inputValues[field] || ""}
                  onChange={(e) => handleInputChange(e, field)}
                />
                {field === "Password" && (
                  <ToggleButton onClick={togglePasswordVisibility}>
                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                  </ToggleButton>
                )}
                {field != "Amount" && errors[field] && (
                  <ErrorMessage>{field} is required</ErrorMessage>
                )}
                {field == "Amount" && errors[field] && (
                  <ErrorMessage>Amount must be minimum 100</ErrorMessage>
                )}
              </InputWrapper>
            ))}
            <Button onClick={handleSubmit}>Submit</Button>
            <CloseButton onClick={closeModal}>Close</CloseButton>
          </ModalContent>
        </ModalOverlay>
      )}

      {showMfaModal && (
        <ModalOverlay>
          <ModalContent>
            <h2>{otptitle}</h2>
            <Input
              type="text"
              placeholder="MFA Key"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <Button onClick={handleMfaSubmit} disabled={loading}>
              {loading ? <ButtonLoader /> : "Verify"}
            </Button>
            <CloseButton onClick={closeModal}>Close</CloseButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </div>
  );
};

export default TopBar;
