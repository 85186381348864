import React, { useEffect, useState } from "react";
import styled, { keyframes, css } from "styled-components";
import icon1 from "../../../assets/images/keypoint.svg";
import icon2 from "../../../assets/images/keypoint2.svg";
import icon3 from "../../../assets/images/keypoint3.svg";

// Keyframes for glowing and fade-in animations
const glow = keyframes`
  0% {
    box-shadow: 0 0 10px rgba(34, 173, 88, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(34, 173, 88, 0.8);
  }
  100% {
    box-shadow: 0 0 10px rgba(34, 173, 88, 0.5);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled Components
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  padding: 50px 20px;
  width: 80%;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 80px;
`;

const KeyPointsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 100px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
`;

const Card = styled.div`
  border: 1px solid #22ad58;
  background-color: #0d221c;
  border-radius: 10px;
  padding: 30px 20px;
  text-align: center;
  flex: 1;
  max-width: 400px;
  min-width: 290px;
  height: 240px;
  opacity: 0; /* Hidden by default */
  transform: translateY(20px); /* Start slightly below the position */
  animation: ${({ isVisible, delay }) =>
      isVisible &&
      css`
      ${fadeIn} 0.8s ease-in-out ${delay}s forwards;
    `};

 
`;

const Icon = styled.div`
  font-size: 50px;
  margin-bottom: 20px;
  color: #00ffcc;

  img {
    height: auto;
  }
`;

const CardTitle = styled.p`
  font-size: 18px;
  margin: 0;
  color: #fff;
  font-weight: 600;
  line-height: 26.6px;
  text-align: center;
`;

// Main Component
const KeyPoints = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 200); // Delay before starting the fade-in animation
    return () => clearTimeout(timer);
  }, []);

  const points = [
    {
      icon: icon1,
      title: "Access to innovative AI technology",
    },
    {
      icon: icon2,
      title: "Effective and simplified investment strategies",
    },
    {
      icon: icon3,
      title: "Support for your financial growth and achieving your ambitions",
    },
  ];

  return (
      <Content>
        <Title>Key Points:</Title>
        <KeyPointsWrapper>
          {points.map((point, index) => (
              <Card
                  key={index}
                  isVisible={isVisible}
                  delay={index * 0.3} // Staggered delay for fade-in effect
              >
                <Icon>
                  <img src={point.icon} alt={`Icon for ${point.title}`} />
                </Icon>
                <CardTitle>{point.title}</CardTitle>
              </Card>
          ))}
        </KeyPointsWrapper>
      </Content>
  );
};

export default KeyPoints;
