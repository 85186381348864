import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useNavigate} from "react-router-dom";
import RequestEngine from "../../core/RequestEngine";

// Styled components for the PlanSelector page
const Header = styled.h1`
    color: #21AD58;
    font-size: 36px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        font-size: 28px;
    }
`;

const SubHeader = styled.h2`
    color: white;
    font-size: 24px;
    margin-bottom: 40px;

    @media (max-width: 768px) {
        font-size: 18px;
    }
`;

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    padding: 40px;
    gap: 20px;

    @media (max-width: 768px) {
        padding: 20px;
    }
`;

const PlanCard = styled.div`
    background: #0f211c;
    border: 2px solid #21AD58;
    border-radius: 20px;
    padding: 20px;
    color: white;
    width: 280px;
    margin: 20px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(51, 255, 153, 0.3);
    transition: transform 0.3s, box-shadow 0.3s;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 8px 20px rgba(51, 255, 153, 0.5);
    }

    @media (max-width: 768px) {
        width: 100%; /* Make the plan card take full width on small screens */
        margin: 10px 0;
    }
`;

const PlanTitle = styled.h3`
    font-size: 22px;
    margin-bottom: 10px;
    color: #21AD58;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

const Price = styled.p`
    font-size: 28px;
    font-weight: bold;
    color: white;
    margin: 20px 0;

    @media (max-width: 768px) {
        font-size: 24px;
    }
`;

const FeatureList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
    color: white;
    text-align: left;
`;

const FeatureItem = styled.li`
    margin: 5px 0;
    font-size: 16px;

    &:before {
        content: "✔";
        color: #21AD58;
        margin-right: 5px;
    }

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const SubscribeButton = styled.button`
    background-color: #21AD58;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    transition: background-color 0.3s;

    &:hover {
        background-color: #45a049;
    }

    @media (max-width: 768px) {
        font-size: 14px;
        padding: 8px 16px;
    }
`;

const PlanSelector = () => {

    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPlans = async () => {
            let engine = new RequestEngine();
            try {
                const response = await engine.getItem("user/plan");
                if (response && response.status === 200 && response.data) {
                    setPlans(response.data.data);
                } else {
                    console.error("Failed to fetch plans:", response.data.message);
                }
            } catch (error) {
                console.error("An error occurred while fetching plans:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPlans();
    }, []);

    if (loading) {
        return <Container>Loading plans...</Container>;
    }

    return (
        <div>
            <h2 style={{marginTop:50}} id="title"></h2>
            <Header>Stay Informed, Stay Profitable</Header>
            <SubHeader>Subscribe Today!</SubHeader>
            <Container>
                {plans.map((plan) => (
                    <PlanCard key={plan.id}>
                        <PlanTitle>{plan.title}</PlanTitle>
                        <Price>{plan.price}</Price>
                        <FeatureList>
                            <FeatureItem>Duration: {plan.duration}</FeatureItem>
                            <FeatureItem>Credits: {plan.credits}</FeatureItem>
                        </FeatureList>
                        <SubscribeButton onClick={() => navigate(`/payment/${plan.id}`)}>Choose Plan</SubscribeButton>
                    </PlanCard>
                ))}
            </Container>
        </div>
    );
};

export default PlanSelector;
