import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import { Memory } from "../../core/Memory";
import Modal from "react-modal";
import SignatureCanvas from "react-signature-canvas";
import {useNavigate} from "react-router-dom";
import check from "../../assets/images/check-box.svg";
import {Utilites} from "../../core/Utilites";



// Main container for the screen
const InvestmentContainer = styled.div`
    background-color: #000;
    padding: 20px;
    color: white;
`;


const ModelContent = styled.div`
    max-height: 500px;
    overflow-y: auto;
    padding: 20px;
    background-color: #0f211c;
    color: white;
    border-radius: 10px;
    text-align: right;
    direction: rtl;
    @media (max-width: 768px) {
        padding: 15px;
    }
`;

// Header for the screen
const Header = styled.h1`
    font-size: 24px;
    margin-bottom: 20px;
    color: #21ad58;
`;

// Steps container
const StepsContainer = styled.div`
    background-color: #0f211c;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    color: white;
`;

// Each step
const StepItem = styled.div`
    margin-bottom: 10px;
    font-size: 16px;
    display: flex;
    align-items: center;

    a {
        background-color: #21ad58;
        color: white;
        text-decoration: none;
        padding: 8px 12px;
        border-radius: 5px;
        margin-left: 10px;

        &:hover {
            background-color: #21ad58;
        }
    }
`;

const PlansWrapper = styled.div`
  display: flex;
  justify-content: space-between; /* Distribute the cards evenly across the available width */
  gap: 20px;
  width: 100%;
  flex-wrap: wrap; /* Allow wrapping for responsiveness */
`;
// Plans container
const PlansContainer = styled.div`
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
`;

// Single plan card
const PlanCard = styled.div`
    flex: 1;
    min-width: 200px;
    background-color: #0f211c;
    color: ${(props) => (props.selected ? "white" : "#d8e4e1")};
    border: ${(props) => (props.selected ? "2px solid #21AD58" : "1px solid #3b3d3b")};
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background-color: ${(props) => (props.selected ? "#21AD58" : "#162421")};
    }
`;

const PlanTitle = styled.h3`
    color: white;
    font-weight: bold;
    margin-bottom: 20px;
`;
const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
  text-align: left;
  color: #b3b3b3;
`;

const Feature = styled.li`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const CheckIcon = styled.span`
  font-size: 16px;
  color: #22ad58;
`;


const SelectButton = styled.a`
    background-color: #21ad58;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 16px;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    text-decoration: none;

    &:hover {
        background-color: #1e964b;
    }
`;

// Modal styles
const modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#0f211c",
        color: "white",
        borderRadius: "10px",
        padding: "20px",
    },
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
};

const InvestmentScreen = () => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isMember, setIsMember] = useState(false);
    const [referral, setReferral] = useState("");
    const sigCanvasRef = useRef(null);

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const navigate = useNavigate(); // Initialize the navigation function

    const storekey = "kmodalShown2";
    useEffect(() => {
        const fetchData = async () => {
            const user = JSON.parse(Memory.getItem("userinfo"));
            setReferral(user.referral);
            setIsMember(user.ismember);
        };
        fetchData();
        const modalShown = Memory.getItem(storekey)

        if (!modalShown) {
            setModalIsOpen(true);
        }
    }, []);

    const handlePlanSelect = (planId) => {
        setSelectedPlan(planId);
    };




    const handleAgree = () => {
        const nameInput = document.querySelector('input[placeholder="___________________________"]');

        if (!nameInput.value.trim()) {
            Utilites.showErrorMessage("Please enter your name.");
            return;
        }
        if (sigCanvasRef.current.isEmpty()) {
            Utilites.showErrorMessage("Please provide your signature.");
            return;
        }

        Memory.setItem(storekey, true);
        setModalIsOpen(false);
    };

    // Hardcoded plans data
    const plans = [
        {
            id: "100$ to 2500$ (50% fees)",
            fees:"50",
            title: "100$ to 2500$ (50% fees)",
            link: "https://vc.cabinet.oneroyal.com/brokeree-pamm/investments/register/440/",
        },
        {
            id: "2501$ to 10k$ (45% fees)",
            fees:"45",
            title: "2501$ to 10k$ (45% fees)",
            link: "https://vc.cabinet.oneroyal.com/brokeree-pamm/investments/register/441/",
        },
        {
            id: "10k$ and above (40% fees)",
            fees:"40",
            title: "10k$ and above (40% fees)",
            link: "https://vc.cabinet.oneroyal.com/brokeree-pamm/investments/register/442/",
        },
    ];



    const clearSignature = () => {
        sigCanvasRef.current.clear();
    };
    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                style={modalStyles}
                contentLabel="Agreement Modal"
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
            >
                <h2 style={{textAlign:"center",direction:"rtl",margin:0}}>عقد التداول بين Multyncome  والمستثمر</h2>
                <ModelContent>
                    <b>تم االتفاق في هذا العقد بين الطرفين</b>

                    <ul>
                        <li> الطرف الأول: الشركة "Multyncome"</li>
                        <li> الطرف الثاني: المستثمر</li>
                    </ul>
                    <br/>
                    <b>بنود العقد</b>
                    <br/>
                    1. نسبة التداول: الشركة تقوم بالتداول باستخدام 30% فقط من رأس المال المودع من قبل المستثمر
                    <br/>
                    2. الأرباح المستهدفة: تستهدف الشركة تحقيق أرباح سنوية بنسبة 60% من قيمة رأس المال المستخدم للتداول.
                    <br/>
                    3. شروط نظام التأمين (اختياري):
                    <ul>
                        <li>دفع مبلغ إضافي يعادل 5% من قيمة رأس المال الأساسي في حوض تأمين خاص.</li>
                        <li>. الالتزام بعدم سحب أي جزء من رأس المال الأساسي لمدة سنة كاملة.</li>
                        <li>عند سحب أي أرباح، يتم خصم القيمة المسحوبة من مبلغ التأمين الموعود</li>
                        <li>مبلغ التأمين غير قابل للاسترداد</li>
                    </ul>

                    <b>فوائد التأمين</b>
                    <ul>
                        <li>في حال وجود تأمين، يتحمل المستثمر 50% فقط من الخسائر</li>
                        <li>في حال عدم وجود تأمين، يتحمل المستثمر كامل الخسارة بحد أقصى 30% من رأس المال المودع</li>
                    </ul>
                    <b>تجديد التأمين</b>
                    <ul>
                        <li>يمكن للمستثمر تجديد التأمين بناءً على الرصيد المتبقي في حسابه عند نهاية السنة بإضافة مبلغ
                            جديد
                        </li>
                    </ul>
                    <b> إمكانية السحب وإغلاق الحساب</b>
                    <ul>
                        <li>يحق للمستثمر سحب أرباحه أو رأس ماله أو إغلاق حسابه في أي وقت دون قيود</li>
                    </ul>
                    <b>إلغاء العقد</b>
                    <ul>
                        <li>يُعتبر العقد لاغيًا إذا قام المستثمر بسحب أي جزء من رأس المال الأساسي خلال السنة</li>
                    </ul>
                    <b>عدم مسؤولية الشركة</b>
                    <ul>
                        <li>الشركة غير مسؤولة عن أي ظروف خاصة تدفع المستثمر إلى سحب رأس المال أو تؤثر على استثماراته.
                        </li>
                    </ul>
                    <b>تنويه</b>
                    <ul>
                        <li>هذا العقد يوفر خيارًا بين الاشتراك في نظام التأمين أو الاستثمار بدون تأمين</li>
                        <li>يعتمد النظام السنوي للعقد على تحقيق التوازن بين تقليل المخاطر وزيادة الأرباح</li>
                    </ul>

                    <b>اختيار المستثمر</b>
                    <p>يرجى اختيار أحد الخيارين التاليين</p>
                    <div>
                        <label>
                            <input type="radio" checked={true} name="insuranceOption" value="agree"/>
                            أوافق على الاشتراك في نظام التأمين بنسبة 50% من الخسائر
                        </label>
                        <br/>
                        <label>
                            <input type="radio" name="insuranceOption" value="disagree"/>
                            لا أرغب بالاشتراك في نظام التأمين، وأتحمل كامل الخسارة بحد أقصى 30%
                        </label>
                    </div>
                    <br/>
                    يرجى الموافقة على الشروط للاستمرار.
                    <div className={"sigstyle"}>
                        <label>
                            اسم المستثمر:
                            <input type="text" placeholder="___________________________"
                                   style={{width: "100%", padding: "10px", marginTop: "5px"}}/>
                        </label>
                        <br/>
                        <label>
                            توقيع المستثمر:
                            <div style={{border: "1px solid #ccc", borderRadius: "5px", marginTop: "5px"}}>
                                <SignatureCanvas
                                    penColor="#21AD58"
                                    ref={sigCanvasRef}
                                    canvasProps={{width: 500, height: 150, className: "sigCanvas"}}
                                    style={{border: "1px solid #000"}}
                                />

                            </div>
                        </label>
                        <button
                            onClick={() => {
                                clearSignature()
                            }}
                            style={{
                                padding: "10px 20px",
                                backgroundColor: "#d9534f",
                                color: "white",
                                border: "none",
                                borderRadius: "5px",
                                marginLeft: "10px",
                                margin:"5px"
                            }}
                        >
                            حذف التوقيع
                        </button>
                    </div>
                    <div>
                        <button onClick={handleAgree} style={{
                            padding: "10px 20px",
                            backgroundColor: "#21ad58",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            margin: "5px"
                        }}>
                            أوافق
                        </button>
                        <button onClick={() => {
                            navigate("/dashboard");
                        }} style={{
                            padding: "10px 20px",
                            backgroundColor: "#d9534f",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            marginLeft: "10px",
                            margin:"5px"
                        }}>
                            لا أوافق
                        </button>
                    </div>
                </ModelContent>

            </Modal>

            <h2 id="title"></h2>
            <InvestmentContainer>
                <Header>For Non-Members</Header>

                {/* Steps */}
                <StepsContainer>
                    <StepItem>
                        Step 1: Register now using this link:{" "}
                        <a href="https://vc.cabinet.oneroyal.com/links/go/5949" target="_blank"
                           rel="noopener noreferrer">
                            Register
                        </a>
                    </StepItem>
                    <StepItem>
                        Step 2: Complete your verification (Password/ID, Proof of Address, Email)
                    </StepItem>
                    <StepItem>Step 3: Select your agreement for customers</StepItem>
                </StepsContainer>

                {/* Plans */}
                <PlansContainer>
                    {plans.map((plan) => (
                        <PlanCard
                            selected={selectedPlan === plan.id}
                            onClick={() => handlePlanSelect(plan.id)}
                        >
                            <PlanTitle>{plan.title.split("(")[0]}</PlanTitle>
                            <FeaturesList>
                                {[
                                    plan.fees+"% fees",
                                    "24/7 Technical Support",
                                    "Always available to resovle your issues",
                                    "Monthly Fee Deduction",
                                    "Fees are calculated and deducted at the start of each month."
                                ].map((feature, idx) => (
                                    <Feature key={idx}>
                                        <CheckIcon>
                                            <img src={check} alt="Check Icon" />
                                        </CheckIcon>
                                        {feature}
                                    </Feature>
                                ))}
                            </FeaturesList>
                            <SelectButton href={plan.link} target="_blank">
                                Press here
                            </SelectButton>
                        </PlanCard>
                    ))}
                </PlansContainer>


            </InvestmentContainer>
            {isMember && (
                <InvestmentContainer>
                    <Header>For Members / Always 35%</Header>

                    {/* Steps */}
                    <StepsContainer>
                        <StepItem>
                            If your amount is below 5000$:
                            <a
                                href="https://vc.cabinet.oneroyal.com/brokeree-pamm/investment-link/339/MultyncomeBronze/?pid=79650"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Press here
                            </a>
                        </StepItem>

                        <StepItem>
                            If your amount is 5000$ and above:
                            <a
                                href="https://vc.cabinet.oneroyal.com/brokeree-pamm/investment-link/338/MultyncomeGold/?pid=79650"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Press here
                            </a>
                        </StepItem>
                    </StepsContainer>
                </InvestmentContainer>
            )}
        </div>
    );
};

export default InvestmentScreen;
