import React from "react";
import styled from "styled-components";
import { Utilites } from "../../core/Utilites";

// Styled Components
const Wrapper = styled.div`
    background-color: #21ad58;
    border-radius: 20px;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    width: 70%;
    max-width: 800px;
    margin: 50px auto;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);

    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
        padding: 30px;
        gap: 20px;
    }
`;

const TextContent = styled.div`
    flex: 1;
    margin-right: 20px;

    @media (max-width: 768px) {
        margin-right: 0;
    }
`;

const Title = styled.h2`
    font-size: 36px;
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 15px;

    @media (max-width: 768px) {
        font-size: 28px;
    }

    @media (max-width: 480px) {
        font-size: 24px;
    }
`;

const Subtitle = styled.p`
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 30px;
    line-height: 1.5;

    @media (max-width: 768px) {
        font-size: 14px;
    }

    @media (max-width: 480px) {
        font-size: 12px;
    }
`;

const Form = styled.form`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: stretch;
        gap: 15px;
    }
`;

const Input = styled.input`
    padding: 15px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    flex: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:focus {
        outline: none;
        box-shadow: 0 0 5px rgba(33, 173, 88, 0.8);
    }

    @media (max-width: 480px) {
        padding: 12px;
        font-size: 12px;
    }
`;

const Button = styled.button`
    padding: 15px 20px;
    background-color: #ffffff;
    color: #2c2c2c;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

    &:hover {
        background-color: #e5f7ed;
        transform: scale(1.05);
    }

    @media (max-width: 480px) {
        padding: 12px 15px;
        font-size: 12px;
    }
`;

// Main Component
const SubscribeSection = () => {
    return (
        <Wrapper>
            {/* Text Content */}
            <TextContent>
                <Title>Subscribe Our News</Title>
                <Subtitle>
                    Hey! Are you tired of missing out on our updates? Subscribe to our
                    news now and stay in the loop!
                </Subtitle>
                <Form>
                    <Input type="email" placeholder="Email Address" />
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            Utilites.showSucessMessage("Thank you!");
                        }}
                    >
                        Submit
                    </Button>
                </Form>
            </TextContent>
        </Wrapper>
    );
};

export default SubscribeSection;
