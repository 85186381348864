import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {Memory} from "../../core/Memory";

const DetailsContainer = styled.div`
    background-color: #0f211c;
    border-radius: 20px;
    padding: 20px;
    color: white;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px;
    margin: 20px auto;
    font-family: "Inter", sans-serif;
`;



const ProfileSection = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;


const ProfileDetails = styled.div`
    display: flex;
    flex-direction: column;

    .name {
        font-size: 20px;
        font-weight: bold;
    }

    .role {
        font-size: 14px;
        color: #999;
    }
`;

const InfoSection = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const InfoRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #333;
    padding-bottom: 5px;

    .label {
        font-size: 14px;
        color: #999;
    }

    .value {
        font-size: 14px;
        font-weight: bold;
        color: #fff;
    }
`;

const Avatar = styled.div`
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #21ad58;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
`;

const DetailsSection = () => {
    const navigate = useNavigate(); // Initialize the navigation function
    const [username, setUsername] = useState("");
    const getInitials = (name) => {
        const names = name?.split(" ") || [""];
        return names.map((n) => n[0]).join("");
    };

    useEffect(() => {
        const fetchData = async () => {
            const user = JSON.parse(Memory.getItem("userinfo"));
            setUsername(user.username);
        };
        fetchData()

    }, []);
    return (
        <DetailsContainer>
            <ProfileSection>
                <Avatar onClick={()=>{
                    navigate("/edit-profile");
                }}>{getInitials(username)}</Avatar>
                <ProfileDetails>
                    <span className="name">Omar Cheikkh</span>
                    <span className="role">CUSTOMER</span>
                </ProfileDetails>
            </ProfileSection>
            <InfoSection>
                <InfoRow>
                    <span className="label">MOBILE :</span>
                    <span className="value">+1-9846499950</span>
                </InfoRow>
                <InfoRow>
                    <span className="label">E-MAIL :</span>
                    <span className="value">omarcheikh@gmail.com</span>
                </InfoRow>
                <InfoRow>
                    <span className="label">LOCATION :</span>
                    <span className="value">25 Melrose Court, Lafayette</span>
                </InfoRow>
            </InfoSection>
        </DetailsContainer>
    );
};

export default DetailsSection;
