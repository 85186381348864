import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import RequestEngine from '../../core/RequestEngine';
import {Utilites} from "../../core/Utilites"; // Assuming RequestEngine is in this path

// Container for the Activity Screen with full width
const ActivityContainer = styled.div`
    background-color: #000;
    min-height: 100vh;
    padding: 20px;
    color: white;
    width: 100%;  /* Full width of the screen */
    box-sizing: border-box;  /* Include padding in the width */
`;

// Table container with full width, but the content centered
const TableWrapper = styled.div`
    background-color: #1c1c28;
    border-radius: 10px;
    padding: 20px;
    max-width: 100%;  /* Full width */
    margin: 0 auto;
    box-sizing: border-box;
`;

// Styled Table
const StyledTable = styled.table`
    width: 100%;  /* Full width */
    border-collapse: collapse;
    font-size: 16px;

    th {
        background-color: #212237;
        color: white;
        padding: 12px;
        text-align: left;
        cursor: pointer;
    }

    td {
        padding: 12px;
        border-bottom: 1px solid #3b3d3b;
        color: white;
    }

    td.amount-negative {
        color: red;
    }

    td.amount-positive {
        color: green;
    }

    td .arrow-up {
        color: green;
    }

    td .arrow-down {
        color: red;
    }
`;

// Activity Screen Component
const MyActivity = () => {
    const [activityData, setActivityData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });


    // Fetch the activity data when the component mounts
    useEffect(() => {
        let mounted = true; // Flag to track if the component is still mounted
        if (loading) return; // Prevent fetching if already loading

        const fetchData = async () => {
            setLoading(true);
            let engine = new RequestEngine();
            try {
                const response = await engine.getItem('user/logs');
                if (response && response.status === 200 && response.data && mounted) {
                    setActivityData(response.data.data); // Update data only if the component is still mounted
                }
            } catch (error) {
                console.error('An error occurred while fetching activity data:', error);
            } finally {
                if (mounted) setLoading(false); // Ensure loading state is updated only if still mounted
            }
        };

        fetchData();

        return () => {
            mounted = false; // Cleanup function to prevent state updates if unmounted
        };
    }, []);

    const sortData = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }

        const sortedData = [...activityData].sort((a, b) => {
            if (key === 'amount') {
                return direction === 'ascending'
                    ? a[key] - b[key]
                    : b[key] - a[key];
            } else if (key === 'createdAt') {
                return direction === 'ascending'
                    ? new Date(a[key]) - new Date(b[key])
                    : new Date(b[key]) - new Date(a[key]);
            } else {
                return direction === 'ascending'
                    ? a[key].localeCompare(b[key])
                    : b[key].localeCompare(a[key]);
            }
        });

        setActivityData(sortedData);
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key !== key) return null;
        if (sortConfig.direction === 'ascending') return <FaArrowUp />;
        return <FaArrowDown />;
    };

    if (loading) {
        return (
            <ActivityContainer>
                <h2>Loading...</h2>
            </ActivityContainer>
        );
    }

    return (
        <ActivityContainer>
            <h2></h2>
            <TableWrapper>
                <StyledTable>
                    <thead>
                    <tr>
                        <th onClick={() => sortData('msg')}>
                            Message {getSortIcon('msg')}
                        </th>
                        <th onClick={() => sortData('createdAt')}>
                            Date {getSortIcon('createdAt')}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {activityData?.map((item, index) => (
                        <tr key={index}>
                            <td>
                                {item.msg}
                            </td>
                            <td>{Utilites.renderDate(item.createdAt)}</td>

                        </tr>
                    ))}
                    </tbody>
                </StyledTable>
            </TableWrapper>
        </ActivityContainer>
    );
};

export default MyActivity;
