import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import Constants from "../../core/Constants";
import RequestEngine from "../../core/RequestEngine";

import usa from "../../assets/images/usa.png";
import saudi from "../../assets/images/saudi.png";
import {Utilites} from "../../core/Utilites";
import store from "../../core/redux/store";
import { selectVideo, setVideos} from "../../core/redux/videoActions";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";

// Styled Components
const VideoPlayerContainer = styled.div`
    position: relative;
    background-color: #000000;
    border-radius: 15px;
    .vp-title{
        display:none !important;
    }

    width: 800px;
    margin: 20px auto;
    margin-top: 70px;
min-height:400px;

    iframe {
        width: 100%;
        height: 400px;
        border: none;
        .vp-title{
            display:none !important;
        }

        @media (max-width: 768px) {
            height: 300px;
        }
    }
    
`;




const FlagContainer = styled.div`
    position: absolute;
    top: 71px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    z-index: 10;
`;

const TitleContainer = styled.h3`
    position: absolute; /* Position it relative to the video container */
    top: 10px; /* Place it near the top */
    left: 10px; /* Align it to the left */
    color: white;
    text-align: ${(props) => (props.isEnglish ? "left" : "right")};
    margin: 0; /* Remove default margin */
    z-index: 10; /* Ensure it appears above the video */
    font-size: 34px;
    text-transform: capitalize;
`;

const ProgressValue = styled.div`
    color: white;
    position: relative;
    top: 22px;
`;

const VideoProgressContainer = styled.div`
    background: #00150F;
    color: white;
    width: 100%;
    height: 4px;
    margin-bottom: 10px;
    margin-top: 10px;
    position: absolute;
    top: 41px;
`;

const VideoProgressLine = styled.div`
    background: #56C46B;
    color: white;
    width: 89%;
    height: 4px;
`;




const FlagButton = styled.button`
    border: ${(props) => (!props.isSelected ? "5px solid #21AD58" : "1px solid #21AD58")};
    padding: 5px;
    background-color: #0F211C;
    cursor: pointer;
    width: 40px;
    height: 34px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: ${(props) => (!props.isSelected ? "0 0 5px #21AD58" : "none")};
    transition: all 0.3s ease;

    &:hover {
        box-shadow: 0 0 5px #21ad58;
    }

    img {
        width: 30px;
        height: auto;
    }
`;



const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;


const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
`;

const RedirectButton = styled.button`
  background: #21ad58;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: #1e8e4f;
  }
`;

const VideoContainer = styled.div`
  background: #1c1c28;
  border-radius: 10px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const WatchText = styled.div`
    background: #21ad58;
    border: none;
    border-radius: 10px;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background: #1e8e4f;
    }
`;

const PlayIcon = styled.span`
  margin-left: 8px;
  font-size: 20px;
`;
const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: #a5a5a5;
  font-size: 20px;
  cursor: pointer;

  &:hover {
    color: white;
  }
`;

const ModalContent = styled.div`
  background: #0F211C;
  padding: 20px;
  border-radius: 10px;
  color: white;
  width: 400px;
  max-width: 90%;
`;

const Question = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
`;

const AnswerButton = styled.button`
  background-color: ${(props) => (props.isSelected ? "#21AD58" : "#333")};
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: 5px;
  cursor: pointer;

  &:hover {
    background-color: #21ad58;
  }
`;





const NavigationButton = styled.button`
  background-color: ${(props) => (props.disabled ? "#333" : "#21ad58")};
  color: ${(props) => (props.disabled ? "#777" : "#fff")};
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  margin: 5px;

  &:hover {
    background-color: ${(props) => (props.disabled ? "#333" : "#21AD58")};
  }
`;

const NavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const VideoPlayer = ({onboarding,ismember}) => {
    const videoRef = useRef(null);
    const [loading, setLoading] = useState(false); // Loading state
    const [questions, setQuestions] = useState([]);
    const [title, setTitle] = useState("");
    const [titlear, setTitlear] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useState("en"); // "en" for English, "ar" for Arabic

    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [volume, setVolume] = useState(1);
    const [isMuted, setIsMuted] = useState(false);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [videoURL, setVideoURL] = useState("");
    const [showQuestionsModal, setShowQuestionsModal] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState({});


    const selectedVideoIndex = useSelector((state) => state.videos.selected); // Index of selected video
    const videos = useSelector((state) => state.videos.list); // List of videos

    useEffect(() => {
        if (selectedVideoIndex !== null && videos[selectedVideoIndex]) {
            const selectedVideo = videos[selectedVideoIndex];
            setTitle(selectedVideo.english);
            setTitlear(selectedVideo.arabic);
            fixVideoLink(selectedLanguage=="en"?selectedVideo.url:selectedVideo.urlarabic);
            setLoading(false);
        }
    }, [selectedVideoIndex, videos]);




    const fetchVideoURL = async (selectIndex = false) => {
        try {
            let engine = new RequestEngine();
            try {
                const response = await engine.getItem("user/video-history-start");
                if (response && response.status === 200 && response.data) {

                    let oldvideos = response.data.data

                    let allvideos = onboarding?Constants.videolistonboarding:Constants.videolist;

                    fillVideos(allvideos);
                    if(onboarding){
                        oldvideos = oldvideos.filter(x=>x.videoid>100)
                    }
                    if(oldvideos.length==0) {
                        Utilites.setTimeout(()=>{
                            const allowVideoId=allvideos.find(x=>!x.locked)?.id || 1
                            unlockVideosandPlay(allowVideoId,allvideos)
                        },1000)
                    }else{

                        let increment  = 0
                        let videoLastid = 1;
                        const foundinprogress = oldvideos.find(x=>x.status=="in progress");
                        if(foundinprogress){
                            videoLastid=foundinprogress.videoid
                            setProgress(foundinprogress.progress)
                        }else{
                            videoLastid=oldvideos[0].videoid
                            increment = 1;
                        }
                        // unlock all the preivous videos and play the next video
                        let updatedVideos = allvideos.map(video => {
                            // Unlock all videos with an id <= videoId
                            if (video?.id <= videoLastid+increment) {
                                return { ...video, locked: false };
                            }
                            return video;
                        });

                        fillVideos(updatedVideos);

                        Utilites.setTimeout(()=>{
                            // Find the next video after the last unlocked one
                            const nextVideoIndex = updatedVideos.findIndex(video => video?.id == videoLastid) + increment

                            if (nextVideoIndex < updatedVideos.length) {
                                if(selectIndex){
                                   Utilites.setTimeout(()=>{
                                       dispatch(selectVideo(nextVideoIndex));
                                   },3000)
                                }else{
                                    unlockVideosandPlay(updatedVideos[nextVideoIndex]?.id,updatedVideos);
                                }

                            } else {
                                console.log("No more videos to play.");
                            }
                        },1000)

                    }

                } else {
                    console.error("Failed to fetch ranks:", response.data.message);
                }
            } catch (error) {
                console.error("An error occurred while fetching ranks:", error);
            } finally {
                setLoading(false); // Stop the loading spinner
            }


        } catch (error) {
            console.error("Error fetching video URL", error);
        }
    };

    // Fetch video URL when the current video index changes
    useEffect(() => {

      Utilites.setTimeout(()=>{
          fetchVideoURL();
      },1000)
    }, []);


    const unlockVideosandPlay = (allowVideoId,externalvideo = null) => {
        if (allowVideoId) {

            const currentVideoList = videos.length?videos:externalvideo;
           const correctVideoIndesx =  currentVideoList.findIndex(x=>x?.id==allowVideoId);
            // Unlock the selected video
            const updatedVideos = currentVideoList.map((video, index) =>
                index === correctVideoIndesx ? { ...video, locked: false } : video
            );

            // Dispatch the updated videos to Redux store
            store.dispatch(setVideos(updatedVideos));

            // Select the unlocked video
            dispatch(selectVideo(correctVideoIndesx));


        }
    };


    const fillVideos=(initialVideos)=>{
        // Dispatch initial videos to Redux

        store.dispatch(setVideos(initialVideos));
    }

    const fixVideoLink = (externalVideo="") => {


        if (!externalVideo) {
            console.error("Invalid video URL");
            return;
        }
        setVideoURL("")
        Utilites.setTimeout(
            ()=>{
                setVideoURL(externalVideo);
            },2000
        )

        setShowQuestionsModal(false); // Hide modal
        setAnswers({}); // Reset answers
        setCurrentQuestionIndex(0); // Reset question index

    };
    const [progress, setProgress] = useState(0); // State for progress percentage

    const handleProgress = (time) => {
        // Calculate progress percentage
        const progressPercentage = time.percent*100;
        // Update current time in MM:SS format
        const minutes = Math.floor(time.seconds / 60);
        const seconds = Math.floor(time.seconds % 60);
        const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

        setCurrentTime(formattedTime); // Update current time
        setProgress(progressPercentage); // Update progress percentage
    };


    const onPause = async (time) => {
        try {

            if(progress==100){
                return;
            }
            let engine = new RequestEngine();
            try {
                const videoName = videos[selectedVideoIndex]?.english || "";
                const _videoId = videos[selectedVideoIndex]?.id || "";
                const response = await engine.postItem("user/video-history", {
                    videoId:_videoId,
                    videoName,
                    progress
                });

                if (response && response.status === 200 && response.data) {
                    Utilites.showSucessMessage("Video Histroy Saved!")
                } else {
                    console.error("Failed to fetch ranks:", response.data.message);
                }
            } catch (error) {
                console.error("An error occurred while fetching ranks:", error);
            } finally {
                setLoading(false); // Stop the loading spinner
            }

            console.log("Video end API call successful");
        } catch (error) {
            console.error("Error calling video end API", error);
        }

    };
    const [duration, setDuration] = useState(0);
    const handleDuration = (duration) => {
        setDuration(duration); // Update total duration
    };


    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);


        const selectedVideo = videos[selectedVideoIndex] || "";
        fixVideoLink(language=="en"?selectedVideo.url:selectedVideo.urlarabic);
    };

    const dispatch = useDispatch();


    const [showEndModal, setShowEndModal] = useState(false);

    const handleVideoEnd = (e) => {
        setShowEndModal(true);
    };

    const handleCloseModal = () => {
        setShowEndModal(false);
    };

    const handlePlayNextVideo = async (skipquestion = false,forceNextVideo=null) => {
        // Call endpoint after video ends
        setShowEndModal(false);
        const havequestions = videos[selectedVideoIndex].questions

        if(!skipquestion && havequestions){
            setQuestions(havequestions)
            setShowQuestionsModal(true);
            return;
        }
        try {

            let engine = new RequestEngine();
            try {
                const videoName = videos[forceNextVideo?forceNextVideo:selectedVideoIndex]?.english || "";
                const _videoId = videos[forceNextVideo?forceNextVideo:selectedVideoIndex]?.id || "";
                const response = await engine.postItem("user/video2end",{
                    videoId:_videoId,
                    videoName,
                    progress
                });
                if (response && response.status === 200 && response.data) {

                    fetchVideoURL(true)

                } else {
                    console.error("Failed to fetch ranks:", response.data.message);
                }
            } catch (error) {
                console.error("An error occurred while fetching ranks:", error);
            } finally {
                 setLoading(false); // Stop the loading spinner
            }

            console.log("Video end API call successful");
        } catch (error) {
            console.error("Error calling video end API", error);
        }

    };



    const handleAnswerSelect = (questionIndex, answer) => {
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionIndex]: answer,
        }));
    };

    const handleNextQuestion = async () => {

        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            // Call endpoint with answers after user submits

            let engine = new RequestEngine();
            try {

                const videoName = videos[selectedVideoIndex]?.english || "";
                const _videoId = videos[selectedVideoIndex]?.id || "";
                const response = await engine.postItem("user/submit-answers",{
                    answers,
                    questions,
                    videoId:_videoId,
                    videoName,
                    progress
                });
                if (response && response.status === 200 && response.data) {
                    const videoInfo = videos[selectedVideoIndex]?.next;
                    if(videoInfo){

                        const nextIndex = videoInfo.find(x=>x.options==answers[0])

                        if(nextIndex?.nextvideo){
                            dispatch(selectVideo(parseInt(nextIndex.nextvideo)));
                           Utilites.setTimeout(()=>{
                               handlePlayNextVideo(true,parseInt(nextIndex.nextvideo)-1)
                           },3000)
                        }else{
                            handlePlayNextVideo(true,selectedVideoIndex)
                        }

                    }



                } else {
                    console.error("Failed to fetch ranks:", response.data.message);
                }
            } catch (error) {
                console.error("An error occurred while fetching ranks:", error);
            } finally {
                setLoading(false); // Stop the loading spinner
            }

            setShowQuestionsModal(false); // Hide modal
            setAnswers({}); // Reset answers
            setCurrentQuestionIndex(0); // Reset question index

            // Move to the next video if applicable
            if (currentVideoIndex < questions.length - 1) {
                setCurrentVideoIndex((prevIndex) => prevIndex + 1);
                setIsPlaying(false); // Reset play state
                videoRef?.current?.load(); // Reload video
            }
        }
    };

    const navigate = useNavigate();
    const handlePreviousQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    return (
        <VideoPlayerContainer>
            <div style={{position: "relative", top: -60}}>
                <div style={{display: "flex", justifyContent: "end"}}>
                    <TitleContainer isEnglish={selectedLanguage === "en"}>
                        {selectedLanguage === "en" ? title : titlear}
                    </TitleContainer>
                    <ProgressValue>{selectedVideoIndex+1}/{videos.length}</ProgressValue>
                </div>


                <VideoProgressContainer>
                    <VideoProgressLine style={{ width: `${progress}%` }} />
                </VideoProgressContainer>

                <FlagContainer>
                    <FlagButton
                        isSelected={selectedLanguage == "en"}
                        onClick={() => handleLanguageChange("ar")}
                    >
                        <img src={saudi} width={20}/>
                    </FlagButton>
                    <FlagButton
                        isSelected={selectedLanguage == "ar"}
                        onClick={() => handleLanguageChange("en")}
                    >
                        <img src={usa} width={20}/>
                    </FlagButton>
                </FlagContainer>
            </div>
            {videoURL ? (
                <Vimeo
                    video={videoURL}
                    autoplay={isPlaying}
                    volume={volume}
                    muted={isMuted}
                    showByline={false}
                    onTimeUpdate={handleProgress}
                    onPause={onPause}
                    onEnd={handleVideoEnd}
                    onDuration={handleDuration}
                    width="100%"
                    height="800px"
                />
            ) : (
                <div>Loading video...</div>
            )}


            {showEndModal && (
                <ModalOverlay>
                    <ModalContent>
                        <CloseButton onClick={handleCloseModal}>×</CloseButton>
                        <VideoContainer>
                            <WatchText onClick={() => {
                                handlePlayNextVideo()
                            }}>
                                Continue Watching ({1+selectedVideoIndex}/{videos.length})
                                <PlayIcon>▶</PlayIcon>
                            </WatchText>
                        </VideoContainer>
                        <ButtonGroup>
                            <RedirectButton onClick={() => {
                                navigate("/investment");
                            }}>
                                Invest
                            </RedirectButton>
                            {!ismember && <RedirectButton onClick={() => {
                                navigate("/subscribe");
                            }}>
                                Be Member
                            </RedirectButton>}
                        </ButtonGroup>
                    </ModalContent>
                </ModalOverlay>
            )}

            {showQuestionsModal && (
                <ModalOverlay>
                    <ModalContent>
                        <Question>{questions[currentQuestionIndex].question}</Question>
                        {questions[currentQuestionIndex].options.map((option, index) => (
                            <AnswerButton
                                key={index}
                                isSelected={answers[currentQuestionIndex] === option}
                                onClick={() => handleAnswerSelect(currentQuestionIndex, option)}
                            >
                                {option}
                            </AnswerButton>
                        ))}
                        <NavigationButtons>
                            <NavigationButton
                                onClick={handlePreviousQuestion}
                                disabled={currentQuestionIndex === 0}
                            >
                                Previous
                            </NavigationButton>
                            <NavigationButton
                                onClick={handleNextQuestion}
                            >
                                {currentQuestionIndex === questions.length - 1 ? "Submit" : "Next"}
                            </NavigationButton>
                        </NavigationButtons>
                    </ModalContent>
                </ModalOverlay>
            )}
        </VideoPlayerContainer>
    );
};

export default VideoPlayer;
