import React from "react";
import styled from "styled-components";
import {  ResponsiveContainer, Area,  AreaChart} from "recharts";
import logo from "../../assets/images/logo.svg";
import { FaArrowUp } from "react-icons/fa";
import { useSpring, animated } from "@react-spring/web";

// Sample Data for the Chart
let data = [

    {
        name: 'Page C',
        uv: 2000,
    },
    {
        name: 'Page B',
        uv: 1000,
    },
    {
        name: 'Page A',
        uv: 4000,
    },
];
const CreditsContainer = styled.div`
    background-color: #0f211c;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
`;

const CreditsDetails = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const CreditsHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    .icon {
        background-color: #21ad58;
        border-radius: 8px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header-text {
        font-size: 16px;
        font-weight: bold;
        color: #cccccc;
    }
`;

const CreditsValue = styled(animated.div)`
    font-size: 48px;
    font-weight: bold;
`;

const CreditsChange = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    color: #21ad58;

    .change {
        font-weight: bold;
    }

    .info {
        color: #cccccc;
    }
`;

const ChartContainer = styled.div`
    width: 200px;
    height: 100px;
    .recharts-wrapper {
        border-radius: 10px;
    }
`;

const AnalyticsCmp = ({label,status,valueNumber}) => {


    const { value } = useSpring({
        from: { value: 0 },
        to: { value: valueNumber },
        config: { duration: 1500 }, // 1.5 seconds duration
    });

    let stroke = "#21AD58";
    let fill = "#103925"
    switch (status){
        case "green":
            stroke = "#21AD58";
            fill = "#103925"
            data=[

                {
                    name: 'Page C',
                    uv: 2000,
                },
                {
                    name: 'Page B',
                    uv: 1000,
                },
                {
                    name: 'Page A',
                    uv: 4000,
                },
            ];
            break;
        case "red":
             stroke = "#E2362E";
             fill = "#322522"
            data = [

                {
                    name: 'Page C',
                    uv: 4000,
                },
                {
                    name: 'Page B',
                    uv: 3000,
                },
                {
                    name: 'Page A',
                    uv: 1000,
                },
            ];
            break;
        default:
            return <span style={{ color: "black" }}>+2%</span>;

    }


    return (
        <CreditsContainer>
            <CreditsDetails>
                <CreditsHeader>
                    <div className="icon">
                        <img src={logo} alt="Icon" style={{ width: "20px", height: "20px" }} />
                    </div>
                    <span className="header-text">{label}</span>
                </CreditsHeader>
                <CreditsValue>
                    {value.to((val) => Math.floor(val))}
                </CreditsValue>
            </CreditsDetails>
            <ChartContainer>
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        data={data}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <Area type="monotone" dataKey="uv" stroke={stroke} fill={fill} />
                    </AreaChart>
                </ResponsiveContainer>
            </ChartContainer>
        </CreditsContainer>
    );
};

export default AnalyticsCmp;
