import React from "react";
import styled from "styled-components";
import lb from "../../assets/images/lb.png";
import alg from "../../assets/images/alg.png";
import award1 from "../../assets/images/award1.png";
import award2 from "../../assets/images/award2.png";
import award3 from "../../assets/images/award3.png";

import trophi1 from "../../assets/images/trophi1.png";
import trophi2 from "../../assets/images/trophi2.png";
import trophie3 from "../../assets/images/trophie3.png";
import {Utilites} from "../../core/Utilites";

const ProfileCardSection = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    border-radius: 30px;
    padding: 20px;
    margin-bottom: 30px;

    @media (max-width: 1024px) {
        gap: 15px;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
`;

const ProfileCard = styled.div`
    background-color: #1a2e26;
    border-radius: 15px;
    padding: 10px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        background-color: #003c2b;
        transform: translateY(-5px);
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
    }

    @media (max-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        max-width: 100%;
        gap: 15px;
    }
`;

const ProfileDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .name {
        font-size: 1.2rem;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 5px;

        @media (max-width: 768px) {
            font-size: 1rem;
        }
    }

    .location {
        font-size: 0.9rem;
        color: #21ad58;

        @media (max-width: 768px) {
            font-size: 0.8rem;
        }
    }
`;

const TrophySection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    img {
        width: 80px;
        margin-bottom: 10px;

        @media (max-width: 768px) {
            width: 60px;
        }
    }

    .title {
        font-size: 0.9rem;
        color: #ffffff;

        @media (max-width: 768px) {
            font-size: 0.8rem;
        }
    }
`;

const TrophySectionImg = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    img {
        width: 100px;
        @media (max-width: 768px) {
            width: 60px;
        }
    }

    .title {
        font-size: 0.9rem;
        color: #ffffff;

        @media (max-width: 768px) {
            font-size: 0.8rem;
        }
    }
`;

const SectionUnderWinner = ({ topCoins, topRecruitment, topRank }) => {


    const grabFlagimg = (country) => {
       if(country.toLocaleString().toLowerCase()=="lebanon"){
           return <img src={lb} style={{ width: 20,
               position: "relative",
               top: 5}} alt="Lebanon Flag" />
       }
       else if(country.toLocaleString().toLowerCase()=="algeria"){
           return <img src={alg} style={{ width: 20,
               position: "relative",
               top: 5}} alt="Algeria Flag" />
       }
    };


    const generateCards = (data) =>
        data?.map((user, index) => (
            <ProfileDetails key={index}>
                <div className="name">{user?.username}</div>
                <div className="location">
                    {grabFlagimg(user?.country)} - Rank #{1+index}
                </div>
            </ProfileDetails>
        ));

    return (
        <ProfileCardSection>
            {/* Recruitment Card */}
            <ProfileCard>
                <TrophySectionImg>
                    <img src={award1} alt="Award 1" />
                </TrophySectionImg>
                <div>{generateCards(topRecruitment)}</div>
                <TrophySection>
                    <img src={trophi1} alt="Trophy" />
                    <div className="title">Recruitment</div>
                </TrophySection>
            </ProfileCard>

            {/* Coins Card */}
            <ProfileCard>
                <TrophySectionImg>
                    <img src={award2} alt="Award 2" />
                </TrophySectionImg>
                <div>{generateCards(topCoins)}</div>
                <TrophySection>
                    <img src={trophi2} alt="Trophy" />
                    <div className="title">Coins</div>
                </TrophySection>
            </ProfileCard>

            {/* Rank Card */}
            <ProfileCard>
                <TrophySectionImg>
                    <img src={award3} alt="Award 3" />
                </TrophySectionImg>
                <div>{generateCards(topRank)}</div>
                <TrophySection>
                    <img src={trophie3} alt="Trophy" />
                    <div className="title">Rank</div>
                </TrophySection>
            </ProfileCard>
        </ProfileCardSection>
    );
};

export default SectionUnderWinner;
