import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import leftarrow from "../../../assets/images/arrow.svg";
import rightarrow from "../../../assets/images/leftarrow.svg";
import profilePlaceholder from "../../../assets/images/user.png"; // Placeholder for profile pictures

// Animations
const slideLeft = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const slideRight = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// Styled Components
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: #0B261C;
  color: #fff;
  padding: 50px 20px;
`;

const Title = styled.h2`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
  padding-left: 100px;
  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

const Subtitle = styled.p`
  font-size: 20px;
  margin-bottom: 40px;
  color: #ffffff;
  text-align: left;
  padding-left: 100px;
  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

const ReviewsWrapper = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  max-width: 100%;
`;

const ReviewsContainer = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: translateX(${(props) => props.translate}%);
`;

const ReviewCard = styled.div`
  min-width: 300px;
  max-width: 450px;
  height: auto;
  border: 1px solid #00ffcc;
  border-radius: 10px;
  padding: 20px;
  margin: 0 10px;
  background-color: #0d221c;
  color: #fff;
  flex-shrink: 0;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: white;
    padding: 10px;
  }

  div {
    display: flex;
    flex-direction: column;

    h3 {
      margin: 0;
      font-size: 20px;
    }

    p {
      margin: 0;
      color: #ffffff;
      font-size: 20px;
    }
  }
`;

const Quote = styled.p`
  font-size: 18px;
  line-height: 1.5;
  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const ArrowsGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;

const NavButton = styled.button`
  background: none;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 50%;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover,
  &:active {
    border-color: #22ad58;
    color: #22ad58;

    img {
      filter: invert(45%) sepia(100%) saturate(350%) hue-rotate(125deg)
      brightness(95%) contrast(90%);
    }
  }

  img {
    transition: transform 0.3s ease, filter 0.3s ease;
  }
`;

// Main Component
const CustomerReviews = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const reviews = [
    {
      name: "Sarah T.",
      role: "Forex Trader",
      quote:
          "Multyncome Company is the most reliable and professional company. I have earned a lot of money from it and I strive to earn more thanks to its great investment system.",
    },
    {
      name: "Emily R.",
      role: "Entrepreneur",
      quote:
          "The most beautiful and wonderful company I have dealt with in terms of credibility ,ease and safety.I am with you until the end,my distinguished company **Multyncome**",
    },
    {
      name: "David L.",
      role: "Investor",
      quote:
          "A wonderful and reliable company, and I have been dealing with it since its inception. I received all the help from it, and I am very happy to be with Multyncome",
    },
    {
      name: "Tarek D",
      role: "",
      quote:
          "The best Forex trading experience I've ever had. I am very convinced that Forex has huge profits, but for the past year I have only reaped losses from the experiments I have undertaken",
    },
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
        prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
    );
  };

  const translateValue = -currentIndex * 100;

  return (
      <Content>
        <Title>What Our Users Are Saying</Title>
        <Subtitle>
          Join Thousands of Happy Traders Who Trust Multyncome for Their Forex
          Success
        </Subtitle>
        <ReviewsWrapper>
          <ReviewsContainer translate={translateValue}>
            {reviews.map((review, index) => (
                <ReviewCard key={index}>
                  <Profile>
                    <img src={profilePlaceholder} alt={`${review.name}'s Profile`} />
                    <div>
                      <h3>{review.name}</h3>
                      <p>{review.role}</p>
                    </div>
                  </Profile>
                  <Quote>{review.quote}</Quote>
                </ReviewCard>
            ))}
          </ReviewsContainer>
        </ReviewsWrapper>
        <ArrowsGroup>
          <NavButton onClick={handlePrev}>
            <img src={leftarrow} alt="leftarrow" width="25px" />
          </NavButton>
          <NavButton onClick={handleNext}>
            <img src={rightarrow} alt="rightarrow" width="25px" />
          </NavButton>
        </ArrowsGroup>
      </Content>
  );
};

export default CustomerReviews;
