import React, { useState, useRef } from "react";
import Vimeo from "@u-wave/react-vimeo";
import styled from "styled-components";

// Styled Components
const VideoPlayerContainer = styled.div`
    position: relative;
    background-color: #000000;
    border-radius: 15px;
    overflow: hidden;
    width: 100%;
    margin: 20px auto;

    @media (max-width: 768px) {
        border-radius: 10px;
        aspect-ratio: 16 / 9; /* Maintain responsive ratio for smaller screens */
        height: auto; /* Adjust height for responsiveness */
    }
`;

const VimeoPlayer = ({ url,callEnd }) => {
    const playerContainerRef = useRef(null); // Reference to the player container
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(1);
    const [isMuted, setIsMuted] = useState(false);


    const handleVideoEnd = (e) => {
       if(callEnd){
           callEnd()
       }
    };

    const handleProgress = (time) => {
        setCurrentTime(time.seconds);
    };

    const handleDuration = (duration) => {
        setDuration(duration);
    };

    return (
        <VideoPlayerContainer ref={playerContainerRef}>
            <Vimeo
                video={url}
                autoplay={isPlaying}
                volume={volume}
                muted={isMuted}
                onTimeUpdate={handleProgress}
                onDuration={handleDuration}
                onEnd={handleVideoEnd}
                width="100%"
                height="800px"
            />
        </VideoPlayerContainer>
    );
};

export default VimeoPlayer;
