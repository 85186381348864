import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import RequestEngine from "../../core/RequestEngine";
import { Utilites } from "../../core/Utilites";
import {FaClock, FaSearch, FaStar} from "react-icons/fa";

// Loader animation keyframes
const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

// Styled Components
const CourseContainer = styled.div`
    background-color: #0a0f0d;
    min-height: 100vh;
    padding: 20px;
    font-family: "Inter", sans-serif;
    color: white;
    margin-top: 47px;
`;

const SearchContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;

    svg {
        position: absolute;
        left: 10px;
        color: #d1d1d1;
    }

    input {
        padding: 10px 10px 10px 40px;
        border-radius: 8px;
        border: 1px solid #1f392e;
        background-color: #0f211c;
        color: white;
        font-size: 16px;
        width: 250px;

        &::placeholder {
            color: #d1d1d1;
        }

        &:focus {
            outline: none;
            border-color: #21ad58;
        }
    }
`;

const SearchInput = styled.input`
    flex: 1;
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    font-size: 16px;
    padding: 10px;

    &::placeholder {
        color: #666;
    }
`;


const Loader = styled.div`
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #21ad58;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: ${spin} 1s linear infinite;
    margin: 100px auto;
`;

const CourseGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    @media (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const CourseCard = styled.div`
    background-color: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 8px 15px rgba(33, 173, 88, 0.3);
    }
`;

const CourseImage = styled.div`
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0C0904;
    border-radius: 10px;
    overflow: hidden;

    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
`;

const CourseTitle = styled.h4`
    font-size: 16px;
    color: black;
    margin: 10px 0 5px 0;
    text-align: center;
`;

const CourseDetail = styled.div`
    font-size: 14px;
    color: #a49c9c;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 10px;

    svg {
        margin-right: 5px;
    }
`;

const Rating = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 5px;

    .star {
        color: #ffc107;
    }

    .reviews {
        color: #a49c9c;
        font-size: 12px;
    }
`;
const PopularCoursesTitle = styled.h2`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #21ad58;
`;

// Component
const CourseScreen = () => {
    const navigate = useNavigate();
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [activeFilter, setActiveFilter] = useState("");

    useEffect(() => {
        const fetchCourses = async () => {
            const engine = new RequestEngine();
            try {
                const response = await engine.getItem("user/course/list");
                if (response && response.status === 200 && response.data) {
                    setCourses(response.data.data);
                } else {
                    Utilites.showErrorMessage(response.data.message);
                }
            } catch (error) {
                console.error("An error occurred while fetching courses:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCourses();
    }, []);

    const handleCourseClick = (id) => {
        navigate(`/courses/${id}`);
    };



    const filteredCourses = courses?.filter((course) => {
        const matchesSearch = course.title.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesFilter = activeFilter ? course.category === activeFilter : true;
        return matchesSearch && matchesFilter;
    });

    if (loading) {
        return (
            <CourseContainer>
                <Loader />
            </CourseContainer>
        );
    }

    return (
        <CourseContainer>
            <SearchContainer>
                <FaSearch />
                <SearchInput
                    type="text"
                    placeholder="Search courses..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </SearchContainer>

            <PopularCoursesTitle>Popular Courses</PopularCoursesTitle>
            <CourseGrid>
                {filteredCourses.map((course) => (
                    <CourseCard onClick={() => handleCourseClick(course.id)}>
                        <CourseImage>
                            <img src={course.fullpicture} alt={course.title} />
                        </CourseImage>
                        <CourseTitle>{course.title}</CourseTitle>
                        <CourseDetail>
                            <FaClock /> {course.duration || ""}
                        </CourseDetail>
                        <Rating>
                            {Array(5).fill(0).map((_, idx) => (
                                <FaStar key={idx} className="star" />
                            ))}
                            <span className="reviews">({course.reviews || "245 Reviews"})</span>
                        </Rating>
                    </CourseCard>
                ))}
            </CourseGrid>
        </CourseContainer>
    );
};

export default CourseScreen;
