import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import RequestEngine from "../../core/RequestEngine";
import { FaStar } from 'react-icons/fa';
import rank from "../../assets/images/menu/rank.png"; // Import the star icon

const CommentContainer = styled.div`
    background-color: #0f211c;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 400px;
    overflow-y: auto;
`;
const Comment = styled.div`
    background-color: #1f392e;
    border-radius: 8px;
    padding: 15px;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const CommentHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const UserInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const UserAvatar = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #d1d1d1;
`;

const UserDetails = styled.div`
    display: flex;
    flex-direction: column;

    .username {
        font-size: 16px;
        font-weight: bold;
        color: white;
    }

    .date {
        font-size: 14px;
        color: #aaa;
    }
`;

const CommentBody = styled.p`
    margin: 0;
    font-size: 16px;
    color: white;
`;

const CommentActions = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 10px;
    color: #d1d1d1;

    .action {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;

        svg {
            font-size: 18px;
        }

        &:hover {
            color: white;
        }
    }
`;
const WriteCommentButton = styled.button`
    background-color: #21ad58;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    align-self: flex-start;
    margin-top: 10px;

    &:hover {
        background-color: #1e8e48;
    }
`;

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Modal = styled.div`
    background-color: #0f211c;
    border-radius: 10px;
    padding: 20px;
    width: 400px;
    max-width: 90%;
    color: white;
`;

const ModalHeader = styled.h3`
    margin: 0 0 10px 0;
    font-size: 20px;
`;

const TextArea = styled.textarea`
    width: 95%;
    height: 100px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #1f392e;
    color: white;
    border: 1px solid #1e8e48;
    border-radius: 8px;

    &:focus {
        outline: none;
        border-color: #21ad58;
    }
`;

const Rating = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;

    .star {
        font-size: 24px;
        color: ${(props) => (props.active ? "#ffc107" : "#aaa")};
        cursor: pointer;

        &:hover {
            color: #ffc107;
        }
    }

    .reviews {
        font-size: 14px;
        color: #d1d1d1;
    }
`;

const ModalActions = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    button {
        padding: 8px 15px;
        border-radius: 8px;
        font-size: 16px;
        cursor: pointer;

        &.cancel {
            background-color: #1f392e;
            color: #d1d1d1;
            border: 1px solid #d1d1d1;

            &:hover {
                background-color: #292929;
            }
        }

        &.submit {
            background-color: #21ad58;
            color: white;
            border: none;

            &:hover {
                background-color: #1e8e48;
            }
        }
    }
`;

const CommentSection = ({ courseId }) => {
    const [comments, setComments] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [commentBody, setCommentBody] = useState("");
    const [rating, setRating] = useState(0);

    const fetchComments = async () => {
        const engine = new RequestEngine();
        try {
            const response = await engine.getItem(`user/course/comments/${courseId}`);
            if (response && response.status === 200 && response.data) {
                setComments(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching comments:", error);
        }
    };
    useEffect(() => {


        fetchComments();
    }, [courseId]);

    const handleSubmit = async () => {
        const engine = new RequestEngine();
        try {
            const response = await engine.postItem(`/submit-comment`, {
                course:courseId,
                body: commentBody,
                rating,
            });
            if (response && response.status === 200) {
                setModalOpen(false);
                setCommentBody("");
                setRating(0);
                fetchComments()

            }
        } catch (error) {
            console.error("Error submitting comment:", error);
        }
    };

    return (
        <div>
            <CommentContainer>
                <h3>Comments</h3>
                {comments.length > 0 ? (
                    comments.map((comment, index) => (
                        <Comment key={index}>
                            <CommentHeader>
                                {/* User Info */}
                                <UserInfo>
                                    <UserAvatar />
                                    <UserDetails>
                                        <span className="username">{comment.user?.username || "Anonymous"}</span>
                                        <span className="date">{comment.date}</span>
                                    </UserDetails>
                                </UserInfo>
                            </CommentHeader>

                            {/* Comment Body */}
                            <CommentBody>{comment.body}</CommentBody>

                        </Comment>
                    ))
                ) : (
                    <p>No comments available</p>
                )}


                {isModalOpen && (
                    <ModalOverlay>
                        <Modal>
                            <ModalHeader>Write a Comment</ModalHeader>
                            <TextArea
                                placeholder="Write your comment here..."
                                value={commentBody}
                                onChange={(e) => setCommentBody(e.target.value)}
                            />
                            <Rating>
                                {Array(5)
                                    .fill(0)
                                    .map((_, idx) => (
                                        <FaStar
                                            key={idx}
                                            className="star"
                                            onClick={() => setRating(idx + 1)}
                                            style={{
                                                color: idx < rating ? "#ffc107" : "#aaa",
                                            }}
                                        />
                                    ))}
                                <span className="reviews">(Your Rating: {rating})</span>
                            </Rating>
                            <ModalActions>
                                <button className="cancel" onClick={() => setModalOpen(false)}>
                                    Cancel
                                </button>
                                <button className="submit" onClick={handleSubmit}>
                                    Submit
                                </button>
                            </ModalActions>
                        </Modal>
                    </ModalOverlay>
                )}
            </CommentContainer>
            <WriteCommentButton onClick={() => setModalOpen(true)}>
                Write a Comment
            </WriteCommentButton>
        </div>


    );
};

export default CommentSection;
