import React, {useState} from "react";
import styled from "styled-components";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FaArrowUp } from "react-icons/fa";
import {Pie, PieChart, ResponsiveContainer, Sector} from "recharts";

const SectionContainer = styled.div`
    background-color: #0f211c;
    border-radius: 20px;
    padding: 20px;
    color: white;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Header = styled.h3`
    font-size: 18px;
    color: #cccccc;
    margin-bottom: 10px;
`;

const SubHeader = styled.h4`
    font-size: 16px;
    color: #cccccc;
`;

const Income = styled.div`
    font-size: 32px;
    font-weight: bold;
    color: #21ad58;

    .percentage {
        font-size: 14px;
        color: #24b25d;
    }
`;

const PerformanceList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 5px;

    li {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
        color: #cccccc;

        .dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }

        &.total-performing .dot {
            background-color: #777;
        }

        &.customers .dot {
            background-color: #4caf50;
        }

        &.members .dot {
            background-color: #21ad58;
        }
    }
`;

const Dropdown = styled.select`
    background-color: #1f392e;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;

    &:focus {
        outline: none;
    }
`;

const ChartContainer = styled.div`
    width: 400px;
    height: 400px;

    @media (max-width: 768px) {
        width: 120px;
        height: 120px;
    }
`;

const CirSection = ({customers,memebers}) => {

    const data = [
        { name: 'Members', value: customers },
        { name: 'Customers', value: memebers },
    ];
    const [activeIndex, setActiveIndex] = useState(0);
   const onPieEnter = (_, index) => {
       setActiveIndex(index)
    };
    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#fff"> {`${(percent * 100).toFixed(2)}%`}</text>
            </g>
        );
    };

    return (
        <SectionContainer>
            <ChartContainer>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart  >
                        <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={data}
                            cx="50%"
                            cy="50%"
                            innerRadius={60}
                            outerRadius={80}
                            fill="#21AD58"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </ChartContainer>
            <InfoContainer>

                <PerformanceList>
                    <li className="total-performing">
                        <span className="dot"></span> {memebers/100+customers/100} Total Performing
                    </li>
                    <li className="customers">
                        <span className="dot"></span> {customers/100}% Customers
                    </li>
                    <li className="members">
                        <span className="dot"></span> {memebers/100}% Members
                    </li>
                </PerformanceList>
            </InfoContainer>
        </SectionContainer>
    );
};

export default CirSection;
