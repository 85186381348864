import React, { useState, useEffect } from "react";
import styled, {keyframes} from "styled-components";
import mail from "../../assets/images/mail.svg";
import user from "../../assets/images/user.png";
import videoIcon from "../../assets/images/video.png"; // Replace with your video icon path
import questionIcon from "../../assets/images/questions.png"; // Replace with your question icon path
import RequestEngine from "../../core/RequestEngine";
import { Utilites } from "../../core/Utilites";
import {useParams} from "react-router-dom";

// Main container
const TrackerContainer = styled.div`
    background-color: #000;
    min-height: 100vh;
    padding: 20px;
    color: white;
    font-family: Arial, sans-serif;
`;

// Section Header with line next to the title
const SectionHeader = styled.div`
    display: flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-size: 27px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #b8b8b8;
    margin: 40px 0 40px;

    &:after {
        content: "";
        flex-grow: 1;
        height: 1px;
        background-color: #333;
        margin-left: 10px;
    }
`;

// Header Container with title and mail image
const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 100px; /* Adjust to your needs */
`;

// Notification card styling
const NotificationCard = styled.div`
    display: flex;
    align-items: center;
    background-color: #0e221c;
    border-radius: 10px;
    padding: 10px 15px;
    margin-bottom: 10px;
    height: 78px;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
        background-color: #1a2b22;
    }
`;

// Icon for notification type
const NotificationIcon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 15px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) =>
    props.type === "video" ? "#4CAF50" : "#4CAF50"}; /* Green for video, red for question */

    img {
        width: 20px;
        height: 20px;
    }
`;

// Loader animation keyframes
const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

// Styled Loader
const Loader = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #21ad58;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
  margin: 100px auto;
`;

// Main container for the activity screen
const ActivityContainer = styled.div`
  min-height: 100vh;
  padding: 20px;
  color: white;
  margin-top: 30px;
  font-family: "Inter", sans-serif;
`;
// Notification content
const NotificationContent = styled.div`
    flex-grow: 1;

    p {
        margin: 0;
        font-size: 14px;
        color: #bbb;
    }

    span {
        color: white;
        font-weight: bold;
        display: block;
        margin-bottom: 3px;
    }

    .time {
        font-size: 12px;
        color: #777;
        margin-top: 5px;
    }
`;

const TrackerDetails = () => {
    const { id } = useParams();
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        handleUserClick(id); // Call the function to fetch rank data
    }, []);

    const handleUserClick = async (id) => {
        let engine = new RequestEngine();
        try {
            const response = await engine.getItem(`user/videoprogress?deep=${id}`);
            if (response && response.status === 200 && response.data) {
                setNotifications(response.data.data); // Store the fetched rank data
            } else {
                console.error("Failed to fetch ranks:", response.data.message);
            }
            setLoading(false)
        } catch (error) {
            console.error("Error performing action:", error);
            Utilites.showErrorMessage("An error occurred while performing action.");
        }
    };

    // Group notifications by date
    const groupedNotifications = notifications.reduce((groups, notification) => {
        // Extract year, month, and day from the createdAt field
        const date = Utilites.renderDate(notification.createdAt);
        if (!groups[date]) {
            groups[date] = [];
        }
        groups[date].push(notification);
        return groups;
    }, {});


    if (loading) {
        return (
            <ActivityContainer>
                <Loader />
            </ActivityContainer>
        );
    }
    return (
        <TrackerContainer>
            <HeaderContainer>
                <img
                    src={mail}
                    alt="Mail Icon"
                    style={{ width: "30px", height: "30px", marginRight: "10px" }}
                />
                <h2>Tracker Order - History</h2>
            </HeaderContainer>

            {Object.keys(groupedNotifications).map((createdAt) => (
                <div key={createdAt}>
                    <SectionHeader>{createdAt}</SectionHeader>
                    {groupedNotifications[createdAt].map((notification) => (
                        <NotificationCard
                            clickable={notification.type === "user"}
                            onClick={() =>
                                notification.type === "user" && handleUserClick(notification.id)
                            }
                            key={notification.id}>
                            <NotificationIcon type={notification.type}>
                                <img
                                    src={notification.type === "video" ? videoIcon : notification.type === "user"?user: questionIcon}
                                />
                            </NotificationIcon>
                            <NotificationContent>
                                <span>{notification.msg} {notification.type === "questions" ? " : \'"+notification.questions[0].question+" \' "+notification.answers[0]:""}</span>
                                <div className="time">
                                    {Utilites.renderDate(notification.createdAt)}
                                </div>
                            </NotificationContent>
                        </NotificationCard>
                    ))}
                </div>
            ))}
        </TrackerContainer>
    );
};

export default TrackerDetails;
