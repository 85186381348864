import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from "react-router-dom";
import { Utilites } from "../../core/Utilites";
import RequestEngine from "../../core/RequestEngine";
import { Memory } from "../../core/Memory";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

// Container for the login screen
const LoginContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
`;

const LoginForm = styled.div`
    background-color: #0F211C;
    color: #ffffff;
    padding: 70px;
    border-radius: 10px;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;

const Title = styled.h1`
    font-size: 32px;
    font-weight: bold;
    color: #21AD58;
    margin-bottom: 10px;
`;

const Subtitle = styled.p`
    font-size: 16px;
    color: #b3b3b3;
    margin-top: 15px;
    margin-bottom: 40px;
`;

const InputField = styled.input`
    width: 96%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid ${({ error }) => (error ? 'red' : '#4caf50')};
    border-radius: 5px;
    background-color: #0f1923;
    color: #ffffff;
    font-size: 14px;

    &:focus {
        outline: none;
        border-color: #21AD58;
    }

    &::placeholder {
        color: #b3b3b3;
    }
`;

const PasswordWrapper = styled.div`
    position: relative;
`;

const ToggleEyeButton = styled.button`
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #b3b3b3;
    font-size: 18px;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;


const SecretCodeModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SecretCodeModalContent = styled.div`
    background-color: black;
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 90%;
`;

const SecretCodeInput = styled.input`
    width: 92%;
    padding: 15px;
    font-size: 16px;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
`;

const SubmitSecretCodeButton = styled.button`
    background-color: #21AD58;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;

    &:hover {
        background-color: #45a049;
    }
`;

const SignInButton = styled.button`
    width: 100%;
    background-color: #21AD58;
    color: #0f1923;
    padding: 12px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        background-color: #00e6b8;
    }

    &:disabled {
        background-color: #666;
        cursor: not-allowed;
    }
`;

const FooterText = styled.p`
    font-size: 14px;
    color: #b3b3b3;
    text-align: center;
    margin-top: 20px;

    a {
        color: #21AD58;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const ErrorMessage = styled.p`
    color: red;
    font-size: 12px;
    margin-bottom: 10px;
`;

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalContent = styled.div`
    background-color: black;
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 90%;
`;

const OtpInput = styled.input`
    width:92%;
    padding: 15px;
    font-size: 16px;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
`;

const VerifyButton = styled.button`
    background-color: #21AD58;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;

    &:hover {
        background-color: #45a049;
    }
`;

const LoginScreen = () => {
    const [emailOrUsername, setEmailOrUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ emailOrUsername: false, password: false });
    const [errorMessage, setErrorMessage] = useState({ emailOrUsername: '', password: '' });
    const navigate = useNavigate();
    const [secretCode, setSecretCode] = useState(''); // State for secret code
    const [mfaEnabled, setMfaEnabled] = useState(false); // To handle MFA check
    useEffect(() => {
        if (emailOrUsername) {
            setError((prev) => ({ ...prev, emailOrUsername: false }));
            setErrorMessage((prev) => ({ ...prev, emailOrUsername: '' }));
        }
        if (password) {
            setError((prev) => ({ ...prev, password: false }));
            setErrorMessage((prev) => ({ ...prev, password: '' }));
        }
    }, [emailOrUsername, password]);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const [otp, setOtp] = useState('');
    const [showSecretCodeModal, setShowSecretCodeModal] = useState(false); // To toggle Sec
    const [showOtpModal, setShowOtpModal] = useState(false); // To toggle OTP modal

    const verifyOtp = async () => {
        if (!otp) {
            Utilites.showErrorMessage("Please enter OTP");
            return;
        }

        setLoading(true);

        try {
            let engine = new RequestEngine();
            const data = { otp };
            const response = await engine.postItem("user/verifymfa", data);
            setLoading(false);
            if (response && response.status === 200 && response.data.success) {
                Memory.setItem('isloggedin', true);
                setShowOtpModal(false);
                navigate('/dashboard');
            } else {
                Utilites.showErrorMessage(response.data.message);
            }
        } catch (error) {
            setLoading(false);
            Utilites.showErrorMessage("Failed to verify OTP. Please try again.");
        }
    };

    const submitForm = async () => {
        let isValid = true;

        setError({ emailOrUsername: false, password: false });
        setErrorMessage({ emailOrUsername: '', password: '' });

        if (!emailOrUsername) {
            setError((prev) => ({ ...prev, emailOrUsername: true }));
            setErrorMessage((prev) => ({ ...prev, emailOrUsername: 'Please enter your email or username.' }));
            isValid = false;
        }

        if (!password) {
            setError((prev) => ({ ...prev, password: true }));
            setErrorMessage((prev) => ({ ...prev, password: 'Please enter your password.' }));
            isValid = false;
        }

        if (!isValid) return;

        setLoading(true);

        try {
            let engine = new RequestEngine();
            const data = { email: emailOrUsername, password,secret_code: secretCode };
            const response = await engine.postItem("user/login", data);
            setLoading(false);

            if (response && response.status === 200 && response.data.success) {
                const token = response.data.data.token;
                const userid = response.data.data.id;
                const userinfo = response.data.data.user;
                const require_code = response.data.data.require_code;
                const needpasswordchanged = response.data.data.user.needpasswordchanged;

                if(needpasswordchanged || needpasswordchanged===null ){
                    Utilites.showErrorMessage("You must reset your password!")
                    navigate("/forgot");
                    return;
                }
                if(require_code){
                    // ask the user to enter a sepcial code and
                    setShowSecretCodeModal(true);
                }else {
                    Memory.setItem('token', token);
                    Memory.setItem('userinfo', JSON.stringify(userinfo));
                    Memory.setItem('userid', userid);
                    if (userinfo.mfa) {
                        setMfaEnabled(true);
                        setShowOtpModal(true);
                    } else {
                        Memory.setItem('isloggedin', true);
                        navigate('/dashboard');
                    }

                }

            } else {
                Utilites.showErrorMessage(response.data.message);
            }
        } catch (error) {
            setLoading(false);
            Utilites.showErrorMessage("An error occurred. Please try again.");
        }
    };

    const submitSecretCode = async () => {
        if (!secretCode) {
            Utilites.showErrorMessage("Please enter the secret code");
            return;
        }
        submitForm();
    };

    return (
        <LoginContainer>
            <LoginForm>
                <Title>Welcome Back!</Title>
                <Subtitle>
                    Hey there! Ready to log in? Just enter your username and password below and you'll be back in action in no time. Let's go!
                </Subtitle>
                <InputField
                    type="text"
                    placeholder="Email or Username"
                    value={emailOrUsername}
                    error={error.emailOrUsername}
                    onChange={(e) => setEmailOrUsername(e.target.value)}
                />
                {error.emailOrUsername && <ErrorMessage>{errorMessage.emailOrUsername}</ErrorMessage>}
                <PasswordWrapper>
                    <InputField
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        value={password}
                        error={error.password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <ToggleEyeButton onClick={toggleShowPassword}>
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </ToggleEyeButton>
                </PasswordWrapper>
                {error.password && <ErrorMessage>{errorMessage.password}</ErrorMessage>}

                <SignInButton onClick={submitForm} disabled={loading}>
                    {loading ? 'Loading...' : 'Sign In'}
                </SignInButton>

                <FooterText>
                    Don't have an account yet? <Link to="/signup">Sign Up</Link>
                </FooterText>
                <FooterText>
                    <Link to="/forgot">Forgot Password</Link>
                </FooterText>
            </LoginForm>

            {showOtpModal && (
                <ModalOverlay>
                    <ModalContent>
                        <h3>Please Enter OTP</h3>
                        <OtpInput
                            type="text"
                            value={otp}
                            placeholder="Enter OTP"
                            onChange={(e) => setOtp(e.target.value)}
                        />
                        <VerifyButton onClick={verifyOtp}>Verify</VerifyButton>
                    </ModalContent>
                </ModalOverlay>
            )}
            {showSecretCodeModal && (
                <SecretCodeModalOverlay>
                    <SecretCodeModalContent>
                        <h3>Please Enter Secret Code</h3>
                        <SecretCodeInput
                            type="text"
                            value={secretCode}
                            placeholder="Enter Secret Code"
                            onChange={(e) => setSecretCode(e.target.value)}
                        />
                        <SubmitSecretCodeButton onClick={submitSecretCode}>Submit</SubmitSecretCodeButton>
                    </SecretCodeModalContent>
                </SecretCodeModalOverlay>
            )}
        </LoginContainer>
    );
};

export default LoginScreen;
