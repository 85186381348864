import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import top1 from "../../../assets/images/hand.png";

// Keyframes for animations
const typing = keyframes`
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
`;

// Styled Components
const Wrapper = styled.div`
    background: url(${top1}) no-repeat center center;
    background-size: cover;
    color: white;
    text-align: center;
    padding: 60px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: 700px;
    width: 100%;

    @media (max-width: 768px) {
        background-position: center;
        padding: 40px 15px;
    }

    @media (max-width: 480px) {
        padding: 30px 10px;
    }
`;

const Content = styled.div`
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;

    @media (max-width: 768px) {
        max-width: 90%;
    }
`;

const AnimatedTitle = styled.h1`
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
    overflow: hidden;
    white-space: nowrap;
    width: 0;
    text-align: center;
    animation: ${typing} 4s steps(30, end) forwards;

    @media (max-width: 768px) {
        font-size: 30px;
    }

    @media (max-width: 480px) {
        font-size: 25px;
    }
`;

const Subtitle = styled.p`
    font-size: 18px;
    line-height: 1.5;
    color: #b3b3b3;
    margin-top: 20px;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 16px;
    }

    @media (max-width: 480px) {
        font-size: 14px;
    }
`;

// Main Component
const Section3 = () => {
    const [hasAnimated, setHasAnimated] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !hasAnimated) {
                    setHasAnimated(true); // Trigger animation only once
                }
            },
            { threshold: 0.5 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, [hasAnimated]);

    return (
        <Wrapper ref={sectionRef} id="partner">
            <Content>
                {hasAnimated && (
                    <AnimatedTitle>Become an Official Partner</AnimatedTitle>
                )}
                <Subtitle>
                    <strong>Final Step:</strong> After reaching top ranks and building a strong
                    network, become an official partner with Multyncome, enjoying exclusive
                    rewards and benefits.
                </Subtitle>
                <Subtitle>
                    <strong>Rewards:</strong> Special recognition, extra profits, luxury
                    awards, and an influential role within the Multyncome community.
                </Subtitle>
            </Content>
        </Wrapper>
    );
};

export default Section3;
