import React from "react";
import styled from "styled-components";
import {ResponsiveContainer, Area, AreaChart, YAxis, XAxis} from "recharts";

// Sample Data for the Chart
let data = [

    {
        name: 'Page C',
        uv: 200,
    },
    {
        name: 'Page B',
        uv: 1000,
    },
    {
        name: 'Page A',
        uv: 4000,
    },
];
const CreditsContainer = styled.div`
    background-color: #0f211c;
    border-radius: 20px;
    padding: 20px;
    display: block;
    color: white;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
`;



const CreditsHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    .icon {
        background-color: #21ad58;
        border-radius: 8px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header-text {
        font-size: 16px;
        font-weight: bold;
        color: #cccccc;
    }
`;


const ChartContainer = styled.div`
    width: 100%;
    height: 350px;
   
`;

const ChartStatus = ({status}) => {



    let stroke = "#21AD58";
    let fill = "#103925"
    switch (status){
        case "green":
            stroke = "#21AD58";
            fill = "#103925"
            data=[

                {
                    name: 'November',
                    uv: 2000,
                },
                {
                    name: 'October',
                    uv: 1000,
                },
                {
                    name: 'December',
                    uv: 4000,
                },
            ];
            break;
        case "red":
            stroke = "#E2362E";
            fill = "#322522"
            data = [

                {
                    name: 'Page C',
                    uv: 4000,
                },
                {
                    name: 'Page B',
                    uv: 3000,
                },
                {
                    name: 'Page A',
                    uv: 1000,
                },
            ];
            break;
        default:
            return <span style={{ color: "black" }}>+2%</span>;

    }


    return (
        <CreditsContainer>
            <CreditsHeader>
                <span className="header-text">Registration Trafic</span>
            </CreditsHeader>
            <ChartContainer>
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        data={data}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                    >


                        <XAxis dataKey="name" />
                        <YAxis />
                        <Area type="monotone" dataKey="uv" stroke={stroke} fill={fill} />
                    </AreaChart>
                </ResponsiveContainer>
            </ChartContainer>
        </CreditsContainer>
    );
};

export default ChartStatus;
