import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useParams } from 'react-router-dom';
import RequestEngine from "../../core/RequestEngine";
import { Utilites } from "../../core/Utilites";
import VimeoPlayer from "./VimeoPlayer";
import CommentSection from './CommentSection';
import share from "../../assets/images/share.png";
import logomini from "../../assets/images/logomini.svg";
import VideoPlayer from "../dashboard/VideoPlayer";
import Playlist from "../dashboard/Playlist";
import {Memory} from "../../core/Memory";
import Constants from "../../core/Constants";
import store from "../../core/redux/store";
import {setVideos} from "../../core/redux/videoActions"; // Import the separate CommentSection component

// Loader animation keyframes
const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;
const CourseDetailsContainer = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 20px;
    padding: 20px;
    color: white;
    background-color: #0a0f0d;
    min-height: 100vh;

    @media (max-width: 1024px) {
        grid-template-columns: 1fr;
    }
`;

const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const CourseDetailsSection = styled.div`
    background-color: #0f211c;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
const ActionContainer = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    margin-left: 20px;
`;

const VideoPlayerWithPlaylistContainer = styled.div`
    display: flex;
  margin-top: 20px;
    justify-content: center; /* Center the components horizontally */
    align-items: stretch; /* Ensure equal height for children */
    background-color: #0F211C;
    border-radius: 30px;
    gap: 0; /* Remove spacing between the components */
    @media (max-width: 768px) {
        flex-direction: column; /* Stack components vertically on smaller screens */
    }
`;
const VideoPlayerContainer = styled.div`
    display: flex;
    align-items: stretch; /* Ensure content fills the container */
    border-radius: 10px;
    overflow: hidden;
`;

const Action = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    color: #d1d1d1;
    font-size: 14px;
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
        color: white;
    }

    svg {
        font-size: 18px;
    }
`;

const InstructorInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;

    .profile {
        width: 50px;
        height: 50px;
        background-color: #000000;
        border-radius: 50%;
        text-align: center;
        border: 2px solid white;
        .img{
            width: 50%;
            margin-top: 5px;
        }
    
    }

    .info {
        display: flex;
        flex-direction: column;

        .name {
            font-size: 16px;
            font-weight: bold;
            color: white;
        }

        .subscribers {
            font-size: 14px;
            color: #aaa;
        }
    }
`;

const CourseTitle = styled.h2`
    font-size: 24px;
    font-weight: bold;
    color: white;
`;

const CourseActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .actions {
        display: flex;
        gap: 15px;

        .action {
            display: flex;
            align-items: center;
            gap: 5px;
            color: #d1d1d1;
            font-size: 14px;
            cursor: pointer;

            &:hover {
                color: white;
            }

            svg {
                font-size: 18px;
            }
        }
    }

    .price-and-button {
        display: flex;
        gap: 15px;
        align-items: center;

        .price {
            font-size: 24px;
            font-weight: bold;
            color: white;
        }

        .buy-button {
            background-color: #21ad58;
            color: white;
            padding: 10px 20px;
            border: none;
            border-radius: 8px;
            cursor: pointer;
            font-size: 16px;
            transition: all 0.3s ease;

            &:hover {
                background-color: #1e8e48;
            }
        }
    }
`;

const MetaPanel = styled.div`
    background-color: #1f392e;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;
    color: #d1d1d1;

    .meta-item {
        display: flex;
        align-items: center;
        gap: 10px;

        svg {
            color: #21ad58;
        }
    }
`;

const PlayerContainer = styled.div`
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    iframe {
        width: 100%;
        height: 400px;
        border: none;

        @media (max-width: 768px) {
            height: 300px;
        }
    }
`;

const Loader = styled.div`
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #21ad58;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: ${spin} 1s linear infinite;
    margin: 100px auto;
`;

const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;


const ActivityContainer = styled.div`
    min-height: 100vh;
    padding: 20px;
    color: white;
`;

const CourseDetails = () => {
    const { id } = useParams();
    const [course, setCourse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [ismember, setIsmember] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(0);
    useEffect(() => {
        const fetchCourseDetails = async () => {
            const engine = new RequestEngine();
            try {
                const response = await engine.getItem(`user/course/details/${id}`);
                if (response && response.status === 200 && response.data) {
                    setCourse(response.data.data);
                } else {
                    Utilites.showErrorMessage(response.data.message);
                }
            } catch (error) {
                console.error("An error occurred while fetching course details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCourseDetails();


        const fetchData = async () => {
            const user = JSON.parse(Memory.getItem("userinfo"));
            setIsmember(user.ismember);
        };
        fetchData();

        if(id.includes("677049ca765cd86aedd67004")){
            store.dispatch(setVideos([]));
        }
    }, [id]);

    if (loading) {
        return (
            <ActivityContainer>
                <Loader />
            </ActivityContainer>
        );
    }

    if (!course) {
        return (
            <CourseDetailsContainer>
                <h2>Course not found</h2>
            </CourseDetailsContainer>
        );
    }


    if( id.includes("677049ca765cd86aedd67004")){
        return   <CourseDetailsContainer>
            <MainContent>
                <CourseDetailsSection>
                    <div style={{display: "flex"}}>

                        <VideoPlayerWithPlaylistContainer>
                            <VideoPlayerContainer>
                                <VideoPlayer onboarding={false}   ismember={ismember}/>
                            </VideoPlayerContainer>
                            <Playlist

                                onSelect={(index) => setSelectedVideo(index)}
                                selectedVideo={selectedVideo}
                            />
                        </VideoPlayerWithPlaylistContainer>

                    </div>

                    <CourseTitle>{course?.title}</CourseTitle>
                </CourseDetailsSection>
            </MainContent>
            <Sidebar>
                <CommentSection courseId={id}/>
            </Sidebar>
        </CourseDetailsContainer>
    }
    return (
        <CourseDetailsContainer>
            <MainContent>
                <PlayerContainer>
                    <VimeoPlayer url={course.vimeo} />
                </PlayerContainer>

                {/* Course Details Section */}
                <CourseDetailsSection>
                    <div style={{display: "flex"}}>
                        <InstructorInfo>
                            <div className="profile">

                            <img src={logomini} style={{    width: "50%",
                                marginTop: 5
                            }} />
                            </div>
                            <div className="info">
                                <span className="name">Multyncome</span>
                            </div>
                        </InstructorInfo>
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <ActionContainer>
                                <Action onClick={()=>{
                                    const shareLink = `https://multyncome.com/courses/${id}`;
                                    navigator.clipboard.writeText(shareLink);
                                    Utilites.showSucessMessage("Link Shared! : " + shareLink);

                                }}>
                                    <img src={share} width={20}/>
                                    Share
                                </Action>
                            </ActionContainer>
                        </div>
                    </div>

                    <CourseTitle>{course.title}</CourseTitle>
                </CourseDetailsSection>
            </MainContent>
            <Sidebar>
                <CommentSection courseId={id}/> {/* Comment Section */}
            </Sidebar>
        </CourseDetailsContainer>
    );
};

export default CourseDetails;
