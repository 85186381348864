import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import RequestEngine from "../../core/RequestEngine";
import { Utilites } from "../../core/Utilites";
import UserSideMenu from "./UserSideMenu";
import img from "../../assets/images/Group.svg";
import AnalyticsCmp from "../common/AnalyticsCmp";
import DetailsSection from "../common/DetailsSection";
import RankSection from "../common/RankSection";

import lines from "../../assets/images/lines.png";
import {Memory} from "../../core/Memory";

// Main container for the Affiliate screen
const AffiliateContainer = styled.div`
    background-color: #000;
    min-height: 100vh;
    padding: 20px;
    color: white;
    margin-top: 30px;

    @media (max-width: 768px) {
        padding: 15px;
    }
`;

// Loader animation keyframes
const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

// Styled Loader
const Loader = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #21ad58;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
  margin: 100px auto;
`;

// Main container for the activity screen
const ActivityContainer = styled.div`
  min-height: 100vh;
  padding: 20px;
  color: white;
  margin-top: 30px;
  font-family: "Inter", sans-serif;
`;



const MainContent = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr; /* 2/3 for Boxcontainer, 1/3 for RankSection */
    gap: 20px;
    margin-top: 20px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr; /* Stack on smaller screens */
    }
`;

const Boxcontainer = styled.div`
    background-color: #0e221c;
    border-radius: 15px;
    padding: 20px;

    @media (max-width: 768px) {
        padding: 15px;
    }
`;

const FilterContainer = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const Dropdown = styled.select`
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    background-color: #1c1c28;
    color: white;
    border: 1px solid #333;
    border-radius: 5px;

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const AffiliateHeader = styled.div`
    background-color: #abdebf;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 20px;
    color: black;

    @media (max-width: 768px) {
        padding: 15px;
    }
`;

const MetricsRow = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin: 20px 0;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const MetricCard = styled.div`
    background-color: #0e221c;
    border-radius: 10px;
    padding: 20px;
    color: white;
    flex: 1;
    min-width: 200px;
    text-align: left;
    margin: 10px;

    @media (max-width: 768px) {
        padding: 15px;
        font-size: 14px;
    }

    p {
        margin: 0;
        font-size: 17px;
    }

    .value {
        font-size: 50px;
        font-weight: bold;
        margin: 10px 0;
        color: white;

        @media (max-width: 768px) {
            font-size: 30px;
        }
    }

    .percentage {
        color: ${(props) => (props.isPositive ? "#21ad58" : "#ff6666")};
        font-size: 14px;

        @media (max-width: 768px) {
            font-size: 12px;
        }
    }
`;

const ProgressBar = styled.div`
    width: 100%;
    height: 14px;
    border-radius: 10px;
    background-color: #e0e0e0;
    overflow: hidden;
    position: relative;

    @media (max-width: 768px) {
        height: 10px;
    }
`;

const Progress = styled.div`
    height: 100%;
    width: ${(props) => props.width || 0}%;
    background-color: #21ad58;
    transition: width 0.5s ease-in-out;
`;

const LegCard = styled.div`
  background-color: #72d175;
  border-radius: 15px;
  padding: 15px 30px;
  margin-bottom: 20px;
  cursor: pointer;
  color: black;
    margin-left: 155px;

  &:hover {
    background-color: #71d175;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    margin-bottom: 15px;
  }
`;

const ClaimButton = styled.button`
  background-color: #21ad58;
  color: white;
  font-size: 18px;
  padding: 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 30px;
  width: 100%;
  animation: ${keyframes`
    0% {
        box-shadow: 0 0 5px #21AD58, 0 0 10px #21AD58, 0 0 20px #21AD58;
    }
    50% {
        box-shadow: 0 0 20px #21AD58, 0 0 30px #21AD58, 0 0 40px #21AD58;
    }
    100% {
        box-shadow: 0 0 5px #21AD58, 0 0 10px #21AD58, 0 0 20px #21AD58;
    }
  `} 1.5s ease-in-out infinite;

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 10px;
  }

  &:hover {
    background-color: #1a9d50;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ButtonLoader = styled.div`
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-top: 3px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.8s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

// Dropdown styling
// Main container for the Affiliate screen



const AnalyticsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
`;

const AffiliateScreen = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [progressLeg1, setProgressLeg1] = useState(0);
  const [progressLeg2, setProgressLeg2] = useState(0);
  const [progressLegC, setProgressLegC] = useState(0);

  const [creditLeg1, setCreditLeg1] = useState(0);
  const [creditLeg2, setCreditLeg2] = useState(0);
  const [creditLegC, setCreditLegC] = useState(0);




  const [rankLeg1, setRankLeg1] = useState("");
  const [rankLeg2, setRankLeg2] = useState("");
  const [rankLegC, setRankLegC] = useState("");

  const [topuser1, setTopuser1] = useState("");
  const [topuser2, setTopuser2] = useState("");
  const [topuserC, setTopuserC] = useState("");

  const [legs, setLegs] = useState([]);
  const [totalCredits, setTotalCredits] = useState(0);
  const [userRank, setUserRank] = useState("Unranked");
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("monthly"); // Default filter is "monthly"

  const [selectedMonth, setSelectedMonth] = useState("January 2025");
  const [selectedRank, setSelectedRank] = useState("");
  const [rankList, setRankList] = useState([]);
  const [currentrank, setCurrentrank] = useState({});
  const [nextrankneeded, setNextrankneeded] = useState({});
  const [canclaim, setCanclaim] = useState(false);

  const [dc, setDC] = useState(0);
    const [dctracker, setDCtracker] = useState(0);

    const [balance, setBalance] = useState(0);

  const openUserSideMenu = (user) => {
    setSelectedUser(user);
  };

  const closeUserSideMenu = () => {
    setSelectedUser(null);
  };


    useEffect(() => {
        const fetchData = async () => {
            const user = JSON.parse(Memory.getItem("userinfo"));
            setBalance(user.balance);
        };
        fetchData()

    }, []);
  // Months dropdown data
  const months = [
      "January 2025",
      "December 2024",
    "November 2024",
    "October 2024",
    "September 2024",
    "August 2024",
  ];

  // Function to fetch legs progress data from API
  const fetchLegData = async () => {
    let engine = new RequestEngine();
    try {
        setLoading(true);
      const response = await engine.getItem(
        `user/legs?filter=${filter}&month=${selectedMonth}&rank=${selectedRank}`
      );
      if (response && response.status === 200 && response.data) {
        const legsData = response.data.data;
        setRankList(legsData.ranklist);
        setCurrentrank(legsData.currentrank);
        setNextrankneeded(legsData.nextrank);
        let _dc = legsData.currentrank?.dc || 0;
        setDC(_dc);
          setDCtracker(_dc-legsData.mainlegs.credit)

        setCanclaim(legsData.canclaim);
        // Update leg progress and credits dynamically
        let progress1 = parseFloat(legsData.legs[0].progress) || 100;
        let progress2 = parseFloat(legsData.legs[1].progress) || 100;
        let progressc = parseFloat(legsData.legs[2].progress) || 100;

        if (legsData.legs[0].progress.toString() == "0") {
          progress1 = 0;
        }
        if (legsData.legs[1].progress.toString() == "0") {
          progress2 = 0;
        }
        if (legsData.legs[2].progress.toString() == "0") {
          progressc = 0;
        }

        if (progress1 == 0 && progress2 == 0) {
          progressc = 0;
        }

        setProgressLeg1(progress1);
        setProgressLeg2(progress2);
        setProgressLegC(progressc);


        setCreditLeg1(legsData.legs[0].credit);
        setCreditLeg2(legsData.legs[1].credit);
        setCreditLegC(legsData.legs[2].credit);

        setRankLeg1(legsData.legs[0].rank);
        setRankLeg2(legsData.legs[1].rank);
        setRankLegC(legsData.legs[2].rank);

        setTopuser1(legsData.legs[0].topuser);
        setTopuser2(legsData.legs[1].topuser);
        setTopuserC(legsData.legs[2].topuser);
        setLegs(legsData.legs);

        setTotalCredits(legsData.mainlegs.credit);
        setUserRank(legsData.mainlegs.rank);
          setLoading(false);
      } else {
        console.error("Failed to fetch legs data:", response.data.message);
      }
    } catch (error) {
      console.error("An error occurred while fetching legs data:", error);
    }
  };

  // Fetch legs data when component mounts or filter changes
  useEffect(() => {
    fetchLegData();
  }, [filter, selectedMonth, selectedRank]);



  // Function to handle click on a leg and navigate to LegChildScreen with the leg ID
  const handleLegClick = (leg) => {
    openUserSideMenu(leg.topuser);
  };

  // Function to handle "CLAIM RANK" button click
  const handleClaimRank = async () => {
    setLoading(true);
    let engine = new RequestEngine();
    try {
      const response = await engine.getItem("claim/" + nextrankneeded?.id);
      if (response && response.status === 200 && response.data.success) {
        Utilites.showSucessMessage("Rank claimed successfully!");
        fetchLegData();
      } else {
        Utilites.showErrorMessage(
          "Failed to claim rank: " + response.data.message
        );
      }
    } catch (error) {
      console.error("An error occurred while claiming rank:", error);
      Utilites.showErrorMessage("An error occurred while claiming rank.");
    } finally {
      setLoading(false);
    }
  };


    if (loading) {
        return (
            <ActivityContainer>
                <Loader />
            </ActivityContainer>
        );
    }
  return (
      <div>

          <AffiliateContainer>
              <AnalyticsContainer>
                  {totalCredits &&<AnalyticsCmp label={"Total Credit"} valueNumber={parseInt(totalCredits)} status={"green"} />}
                  {balance &&<AnalyticsCmp label={"Total Balance"} valueNumber={parseInt(balance)} status={"green"} />}

                  {<AnalyticsCmp label={"Active Legs"} valueNumber={parseInt(3)} status={"green"} />}


              </AnalyticsContainer>
              <FilterContainer>
                  <Dropdown
                      value={selectedRank}
                      onChange={(e) => setSelectedRank(e.target.value)}
                  >
                      <option value="">Current Progress</option>
                      {rankList.map((rank, index) => (
                          <option key={index} value={rank._id}>
                              {rank.title}
                          </option>
                      ))}
                  </Dropdown>
                  {filter == "monthly" && (
                      <Dropdown
                          value={selectedMonth}
                          onChange={(e) => setSelectedMonth(e.target.value)}
                      >
                          <option value="">Select Month</option>
                          {months.map((month, index) => (
                              <option key={index} value={month}>
                                  {month}
                              </option>
                          ))}
                      </Dropdown>
                  )}
                  <Dropdown
                      value={filter}
                      onChange={(e) => {
                          setSelectedMonth("");
                          setFilter(e.target.value);
                      }}
                  >
                      <option value="monthly">Monthly</option>
                      <option value="yearly">Yearly</option>
                  </Dropdown>


              </FilterContainer>


              <MainContent>
                  <Boxcontainer>
                      <AffiliateHeader>
                          <div style={{display: "flex", alignItems: "center"}}>
                              <img src={img} alt="icon"/>
                              <div
                                  style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "100%",
                                  }}
                              >
                                  <h2 style={{color: "#545454", margin: "0px", fontSize: "20px"}}>
                                      Total
                                  </h2>
                                  <div
                                      style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          gap: "5px",
                                          color: "#545454",
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                          width: "100%",
                                      }}
                                  >
                                      Next Rank: {nextrankneeded?.title}
                                  </div>
                              </div>
                          </div>
                          <div>
                              <div
                                  style={{
                                      display: "flex",
                                      justifyContent: "space-evenly",
                                      alignItems: "center",
                                  }}
                              >
                                  <ProgressBar>
                                      <Progress width={progressLeg1}/>
                                  </ProgressBar>
                                  <p
                                      style={{
                                          color: "#545454",
                                          fontSize: "20px",
                                          fontWeight: "bold",
                                          marginLeft: "10px",
                                          margin: "5px",
                                      }}
                                  >
                                      {progressLeg1.toFixed(0)}%
                                  </p>
                              </div>
                          </div>
                          <p
                              style={{
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  lineHeight: "15px",
                                  color: "#545454",
                                  margin: "5px",
                                  marginLeft: "20px",
                              }}
                          >
                              Credit: {totalCredits} credits
                          </p>
                          <p
                              style={{
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  lineHeight: "15px",
                                  color: "#545454",
                                  margin: "0px",
                                  marginLeft: "20px",
                              }}
                          >
                              User Rank: {userRank}
                          </p>
                      </AffiliateHeader>
                      <img src={lines} style={{float: "left",
                          width: 141
                      }} />
                      <LegCard onClick={() => handleLegClick({topuser: topuser1})}>
                          <div style={{display: "flex", alignItems: "center"}}>
                              <img src={img} alt="icon"/>
                              <div
                                  style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "100%",
                                  }}
                              >
                                  <h2 style={{color: "#545454", margin: "0px", fontSize: "20px"}}>
                                      LEG 1
                                  </h2>

                                  <div style={{marginBottom: "5px"}}>
                                      <div
                                          style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              gap: "5px",
                                              color: "#545454",
                                              fontSize: "16px",
                                              fontWeight: "bold",
                                              width: "100%",
                                          }}
                                      >
                                          <div>User: {topuser1?.username}</div>
                                          <p style={{margin: "0px", marginRight: "80px"}}>
                                              {creditLeg1} credits
                                          </p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div>
                              <div
                                  style={{
                                      display: "flex",
                                      justifyContent: "space-evenly",
                                      alignItems: "center",
                                  }}
                              >
                                  <ProgressBar>
                                      <Progress width={progressLeg1}/>
                                  </ProgressBar>

                                  <p
                                      style={{
                                          color: "#545454",
                                          fontSize: "20px",
                                          fontWeight: "bold",
                                          marginLeft: "10px",
                                          margin: "0px",
                                      }}
                                  >
                                      {progressLeg1.toFixed(0)}%
                                  </p>
                              </div>
                          </div>
                      </LegCard>
                      <LegCard onClick={() => handleLegClick({topuser: topuser2})}>
                          <div style={{display: "flex", alignItems: "center"}}>
                              <img src={img} alt="icon"/>
                              <div
                                  style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "100%",
                                  }}
                              >
                                  <h2 style={{color: "#545454", margin: "0px", fontSize: "20px"}}>
                                      LEG 2
                                  </h2>

                                  <div style={{marginBottom: "5px"}}>
                                      <div
                                          style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              gap: "5px",
                                              color: "#545454",
                                              fontSize: "16px",
                                              fontWeight: "bold",
                                              width: "100%",
                                          }}
                                      >
                                          <div>User: {topuser2?.username}</div>
                                          <p style={{margin: "0px", marginRight: "80px"}}>
                                              {creditLeg2} credits
                                          </p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div>
                              <div
                                  style={{
                                      display: "flex",
                                      justifyContent: "space-evenly",
                                      alignItems: "center",
                                  }}
                              >
                                  <ProgressBar>
                                      <Progress width={progressLeg2}/>
                                  </ProgressBar>

                                  <p
                                      style={{
                                          color: "#545454",
                                          fontSize: "20px",
                                          fontWeight: "bold",
                                          marginLeft: "10px",
                                          margin: "0px",
                                      }}
                                  >
                                      {progressLeg2.toFixed(0)}%
                                  </p>
                              </div>
                          </div>
                      </LegCard>
                      <LegCard onClick={() => handleLegClick({topuser: topuserC})}>
                          <div style={{display: "flex", alignItems: "center"}}>
                              <img src={img} alt="icon"/>
                              <div
                                  style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "100%",
                                  }}
                              >
                                  <h2 style={{color: "#545454", margin: "0px", fontSize: "20px"}}>
                                      LEG C
                                  </h2>

                                  <div style={{marginBottom: "5px"}}>
                                      <div
                                          style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              gap: "5px",
                                              color: "#545454",
                                              fontSize: "16px",
                                              fontWeight: "bold",
                                              width: "100%",
                                          }}
                                      >
                                          <div>User: {topuserC?.username}</div>
                                          <p style={{margin: "0px", marginRight: "80px"}}>
                                              {creditLegC} credits
                                          </p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div>
                              <div
                                  style={{
                                      display: "flex",
                                      justifyContent: "space-evenly",
                                      alignItems: "center",
                                  }}
                              >
                                  <ProgressBar>
                                      <Progress width={progressLegC}/>
                                  </ProgressBar>

                                  <p
                                      style={{
                                          color: "#545454",
                                          fontSize: "20px",
                                          fontWeight: "bold",
                                          marginLeft: "10px",
                                          margin: "0px",
                                      }}
                                  >
                                      {progressLegC.toFixed(0)}%
                                  </p>
                              </div>
                          </div>
                      </LegCard>
                  </Boxcontainer>
                  <RankSection/>
              </MainContent>
              {progressLeg1 === 100 &&
                  progressLeg2 === 100 &&
                  progressLegC === 100 &&
                  canclaim && (
                      <ClaimButton onClick={handleClaimRank} disabled={loading}>
                          {loading ? <ButtonLoader /> : "CLAIM RANK"}
                      </ClaimButton>
                  )}

              {selectedUser && (
                  <UserSideMenu user={selectedUser} onClose={closeUserSideMenu} />
              )}


              <MetricsRow>
                  <MetricCard isPositive={true}>
                      <h3>DC</h3>
                      <div className="value">{dc}</div>
                      {dctracker>=0 ?<div>dc tracker remaining :  {dctracker}</div>:null}
                  </MetricCard>
                  <MetricCard isPositive={true}>
                      <h3>Total Credit</h3>
                      <div className="value">{totalCredits}</div>
                  </MetricCard>
              </MetricsRow>
          </AffiliateContainer>

      </div>
  );
};

export default AffiliateScreen;
