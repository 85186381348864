import React, {useEffect, useState} from "react";
import styled, {keyframes} from "styled-components";
import {FaSort, FaArrowUp, FaArrowDown, FaClock} from "react-icons/fa";
import RequestEngine from "../../core/RequestEngine";
import {Utilites} from "../../core/Utilites";
import logo from "../../assets/images/logo.svg";
import {Link, useParams} from "react-router-dom";
import VimeoPlayer from "../course/VimeoPlayer";
import Modal from "react-modal";

const ScreenContainer = styled.div`
    min-height: 100vh;
    padding: 20px;
    margin-top: 20px;
    font-family: "Inter", sans-serif;
    color: white;
`;



const Section = styled.div`
    margin-bottom: 50px;
`;

const ProgressText = styled.div`
  margin-top: 8px;
  font-size: 14px;
  color: white;
  text-align: right;
`;

// Progress Bar Styling
const ProgressBarContainer = styled.div`
  margin: 20px 0;
  width: 100%;
  background-color: #0f211c;
  border-radius: 20px;
  overflow: hidden;
  height: 20px;
`;

const fillAnimation = keyframes`
  from {
    width: 0%;
  }
  to {
    width: 0%;
  }
`;


const ProgressFill = styled.div`
    height: 100%;
    background-color: #21ad58;
    width: ${({ width }) => width || "0%"}; /* Dynamically set width from props, default to 0% */
    animation: ${fillAnimation} 1s ease-out;
`;
const SectionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    margin-top: 25px;
    h3 {
        font-size: 18px;
        font-weight: bold;
        color: white;
    }

    .sort-button {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        background-color: #0f211c;
        border: 1px solid #21ad58;
        border-radius: 8px;
        padding: 5px 10px;
        color: white;
        cursor: pointer;

        &:hover {
            background-color: #21ad58;
        }
    }
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
`;

const TableRow = styled.tr`
    &:nth-child(even) {
        background-color: #2c2c2c;
    }

    &:hover {
        background-color: #333;
    }
`;

const JoinButton = styled.button`
    background: linear-gradient(90deg, #21ad58, #1e964b);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 25px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease;

    &:hover {
        opacity: 0.9;
        transform: scale(1.05);
    }

    @media (max-width: 768px) {
        font-size: 13px;
        padding: 8px 20px;
    }
`;


const TableHeader = styled.th`
    text-align: left;
    padding: 15px;
    font-size: 14px;
    background-color: #0f211c;
    color: white;
    border-bottom: 1px solid #333;
`;


const TableCell = styled.td`
    padding: 15px;
    font-size: 14px;
    color: ${(props) => (props.isRejected ?  "#FF0000":"#d1d1d1")}; /* Green if isGreen is true, else Red */
    a{
        color: white;
        text-decoration: underline;
    }
`;

const CourseGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    @media (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const CourseCard = styled.div`
    background-color: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 8px 15px rgba(33, 173, 88, 0.3);
    }
`;

const CourseImage = styled.div`
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0C0904;
    border-radius: 10px;
    overflow: hidden;

    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
`;

const CourseTitle = styled.h4`
    font-size: 16px;
    color: black;
    margin: 10px 0 5px 0;
    text-align: center;
`;

const CourseDetail = styled.div`
    font-size: 14px;
    color: ${(props) => (props.isGreen ? "#21AD58" : "#FF0000")}; /* Green if isGreen is true, else Red */
    display: flex;
    font-weight: bold;
    align-items: center;
    text-transform: capitalize;
    justify-content: center;
    margin-bottom: 10px;

    svg {
        margin-right: 5px;
    }
`;

const LightboxOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;

const VideoContainer = styled.div`
    background-color: #000;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 800px;
    text-align: center;
    iframe {
        width: 100%;
        height: 400px;
        border: none;

        @media (max-width: 768px) {
            height: 300px;
        }
    }
    
    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 24px;
        color: white;
        cursor: pointer;
    }
`;

const Screen = () => {
    const { id } = useParams();
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true); // Show loading spinner while fetching data
    const [customers, setCustomers] = useState([]);
    const [progress, setProgress] = useState("0");


    const [modalIsOpen, setModalIsOpen] = useState(false); // Modal state
    const [questionList, setQuestionList] = useState([]); // Store the question list
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Track the current question
    const [responses, setResponses] = useState({}); // Store user responses

    const handleOpenModal = (notification) => {
        setMissionid(notification._id)
        setQuestionList(notification.variationlist);
        setCurrentQuestionIndex(0); // Start with the first question
        setResponses({}); // Reset responses
        setModalIsOpen(true);
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };
    useEffect(() => {
        fetchProgress(); // Call the function to fetch rank data
    }, []);

    const fetchProgress = async () => {
        let engine = new RequestEngine();
        try {
            const response = await engine.getItem(`user/day/${id}`);

            if (response && response.status === 200 && response.data) {
                if(response.data.message){
                    Utilites.showErrorMessage(response.data.message);
                    setLoading(false); // Stop the loading spinner
                    return;
                }

                const missions = response.data.data.mission;
                setProgress(response.data.data.progress)

// Filter missions based on vimeorul value
                const missionsWithVimeoUrl = missions.filter(mission => mission.vimeo && mission.vimeo.trim() !== "");
                const missionsWithOutVimeoUrl = missions.filter(mission => mission.vimeo=="");

// Set filtered data
                setCustomers(missionsWithOutVimeoUrl); // Store missions with empty vimeorul
                setCourses(missionsWithVimeoUrl);
            } else {
                console.error("Failed to fetch mission:", response.data.message);
                debugger
            }
        } catch (error) {
            console.error("An error occurred while fetching ranks:", error);
        } finally {
            setLoading(false); // Stop the loading spinner
        }
    };


    const [lightbox, setLightbox] = useState(false); // Lightbox state
    const [videoUrl, setVideoUrl] = useState(""); // Video URL state
    const [missionid, setMissionid] = useState("");
    const handleCardClick = (video) => {
        setVideoUrl(video.vimeo); // Set the video URL
        setMissionid(video._id)

        setLightbox(true); // Open the lightbox
    };

    const closeLightbox = () => {
        setLightbox(false); // Close the lightbox
        setVideoUrl(""); // Clear the video URL
    };

    const [proofImages, setProofImages] = useState({});
    const [tasks, setTasks] = useState([]);
    const [completedTasks, setCompletedTasks] = useState({});

    const handleImageUpload = (event, task) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64Image = reader.result;
                setProofImages((prev) => ({ ...prev, [task.title]: base64Image }));
                setCompletedTasks((prev) => ({ ...prev, [task.title]: true }));

                // Call uploadTask to send the proof to the server
                await uploadTask(task._id, base64Image);
            };
            reader.readAsDataURL(file);
        }
    };
    const uploadTask = async (taskId, picture) => {
        try{
            let engine = new RequestEngine();
            const data = {
                mission: taskId,
                day:id,
                picture
            };
            const response = await engine.postItem("user/missions/save", data);
            if (response && response.status === 200 && response.data) {
                Utilites.showSucessMessage("Task Completed, waiting Admin Approval!");
                fetchProgress()
            }
        }catch (e) {

        }
    }

    const handleNextQuestion = () => {
        if (currentQuestionIndex < questionList.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const handlePreviousQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    const handleResponseChange = (answer) => {
        setResponses((prev) => ({
            ...prev,
            [questionList[currentQuestionIndex].sno]: answer,
        }));
    };
    const callEnd= async () => {
        setLightbox(false); // Close the lightbox
        setVideoUrl(""); // Clear the video URL
        setMissionid("");
        let engine = new RequestEngine();
        const data = {
            mission: missionid,
            day:id
        };
        const response = await engine.postItem("user/missions/save", data);
        if (response && response.status === 200 && response.data) {

            Utilites.showSucessMessage("Task Completed!");
            fetchProgress()
        }
    };

    const handleSubmitResponses = async () => {

        try {
            let engine = new RequestEngine();
            const data = {
                day: id,
                mission: missionid,
                responses,
            };
            const response = await engine.postItem("user/missions/save", data);
            if (response && response.status === 200) {
                Utilites.showSucessMessage("Task Completed, waiting Admin Approval!");
                fetchProgress()
            } else {
                Utilites.showErrorMessage("Failed to save responses.");
            }
        } catch (error) {
            console.error("Error saving responses:", error);
        } finally {
            setModalIsOpen(false);
        }
    };

    return (
        <ScreenContainer>
            <Section>
                <SectionHeader>Watch Videos</SectionHeader>
                <CourseGrid>
                    {courses.map((course) => (
                        <CourseCard onClick={() => {
                            handleCardClick(course)
                        }}>
                            <CourseImage>
                                <img src={logo} alt={course.title} />
                            </CourseImage>
                            <CourseTitle>{course.title}</CourseTitle>
                            <CourseDetail isGreen={course.status=="completed"}>
                               {course.status}
                            </CourseDetail>
                        </CourseCard>
                    ))}
                </CourseGrid>

                <ProgressBarContainer>
                    <ProgressFill width={progress.toString()+"%"} />
                </ProgressBarContainer>
                <ProgressText>{progress}% progress</ProgressText>
                <SectionHeader>Missions</SectionHeader>
                <div >
                    <Table>
                        <thead>
                        <tr>
                            <TableHeader>Task</TableHeader>
                            <TableHeader>Coins</TableHeader>
                            <TableHeader>Action</TableHeader>
                            <TableHeader>Date</TableHeader>
                            <TableHeader>Status</TableHeader>
                            <TableHeader>Comment</TableHeader>
                        </tr>
                        </thead>
                        <tbody>
                        {customers.map((notification) => (
                            <TableRow style={{background:"#094325"}} >
                                <TableCell>
                                    <b>{notification.title}</b>
                                </TableCell>

                                <TableCell>{notification.coin}</TableCell>
                                {!notification.status.includes("pending") ?<TableCell>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        style={{display: "none"}}
                                        id={`upload-${notification.title}`}
                                        onChange={(e) => handleImageUpload(e, notification)}
                                    />
                                    {notification.missiontype == "task" ? <JoinButton onClick={(e) => {

                                        document
                                            .getElementById(`upload-${notification.title}`)
                                            .click()
                                    }}>Upload</JoinButton> : ""}

                                    {notification.missiontype == "questions" ? <JoinButton onClick={() => handleOpenModal(notification)}>
                                        Answer
                                    </JoinButton> : ""}

                                </TableCell>:<TableCell>-</TableCell>}
                                <TableCell>{Utilites.renderDate(notification.createdAt)}</TableCell>
                                <TableCell isRejected={notification.status.includes("reje")}>{notification.status}</TableCell>
                                <TableCell>{notification.comment}</TableCell>
                            </TableRow>
                        ))}
                        </tbody>
                    </Table>
                </div>


            </Section>

            {lightbox && (
                <LightboxOverlay onClick={closeLightbox}>
                    <VideoContainer onClick={(e) => e.stopPropagation()}>
                        <span className="close" onClick={closeLightbox}>
                            &times;
                        </span>
                        <div>
                            <VimeoPlayer url={videoUrl} callEnd={callEnd} />
                        </div>

                    </VideoContainer>
                </LightboxOverlay>
            )}

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={handleCloseModal}
                contentLabel="Question Modal"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: '#0f211c',
                        color: 'white',
                        borderRadius: '10px',
                        padding: '20px',
                        width: '90%',
                        maxWidth: '500px'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)'
                    }
                }}
            >
                <h2>Answer the Questions</h2>
                {questionList.length > 0 && (
                    <div>
                        <h4>{questionList[currentQuestionIndex].sno}. {questionList[currentQuestionIndex].question}</h4>
                        <div>
                            {questionList[currentQuestionIndex].answers.split(',').map((answer, idx) => (
                                <div key={idx}>
                                    <input
                                        type="radio"
                                        id={`q${questionList[currentQuestionIndex].sno}-a${idx}`}
                                        name={`q${questionList[currentQuestionIndex].sno}`}
                                        value={answer}
                                        checked={responses[questionList[currentQuestionIndex].sno] === answer}
                                        onChange={() => handleResponseChange(answer)}
                                    />
                                    <label htmlFor={`q${questionList[currentQuestionIndex].sno}-a${idx}`} style={{ marginLeft: '10px' }}>
                                        {answer}
                                    </label>
                                </div>
                            ))}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                            <JoinButton onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0}>
                                Previous
                            </JoinButton>
                            {currentQuestionIndex < questionList.length - 1 ? (
                                <JoinButton onClick={handleNextQuestion}>
                                    Next
                                </JoinButton>
                            ) : (
                                <JoinButton onClick={handleSubmitResponses}>
                                    Submit
                                </JoinButton>
                            )}
                        </div>
                    </div>
                )}
            </Modal>

        </ScreenContainer>
    );
};

export default Screen;
