import React, { useMemo, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../App.css";
import RequestEngine from "../../core/RequestEngine";
import TopBar from "./TopBar";
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
} from "chart.js";
import { Utilites } from "../../core/Utilites";
import { Memory } from "../../core/Memory";

// Register the Chart.js components
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

// Styled Components

const Boxcontent = styled.div`
padding:50px;`


const HeroSection = styled.div`
    text-align: left;
    padding: 30px 20px;
    border-radius: 10px;
    margin-bottom: 0px;
    padding-bottom: 0;
    padding-top: 10px;

    h1 {
        font-size: 30px;
        color: #ffffff;
        margin-top: 0;
        margin-bottom: 0;
        font-weight: bold;
        line-height: 1.5;
        text-align: center;

        span {
            color: #21AD58;
        }

        @media (max-width: 768px) {
            font-size: 38px;
        }
    }

    p {
        font-size: 40px;
        color: #ffffff;
        margin-top: 10px;


        b {
            color: #21AD58;
        }

        @media (max-width: 768px) {
            font-size: 16px;
        }
    }
`;

const GlowingRedText = styled.div`
    font-size: 16px;
    color: red;
margin-top: 0px;
    animation: ${keyframes`
        0% {
            text-shadow: 0 0 5px red, 0 0 10px red, 0 0 15px red, 0 0 20px red;
        }
        50% {
            text-shadow: 0 0 10px red, 0 0 20px red, 0 0 30px red, 0 0 40px red;
        }
        100% {
            text-shadow: 0 0 5px red, 0 0 10px red, 0 0 15px red, 0 0 20px red;
        }
    `} 1.5s ease-in-out infinite;
    text-align: center;
`;

const GlowingGreenText = styled.div`
color: #21AD58;
    display: inline;
margin-top: 0px;
    animation: ${keyframes`
        0% {
            text-shadow: 0 0 5px #21AD58, 0 0 10px #21AD58, 0 0 15px #21AD58, 0 0 20px #21AD58;
        }
        50% {
            text-shadow: 0 0 10px #21AD58, 0 0 20px #21AD58, 0 0 30px #21AD58, 0 0 40px #21AD58;
        }
        100% {
            text-shadow: 0 0 5px #21AD58, 0 0 10px #21AD58, 0 0 15px #21AD58, 0 0 20px #21AD58;
        }
    `} 1.5s ease-in-out infinite;
`;





const Dashboard = () => {
    const [history, setHistory] = useState([]);
    const [topRank, setTopRank] = useState([]);
    const [topCoins, setTopCoins] = useState([]);

    const [commitions, setCommitions] = useState(0);


    const [topRecruitment, setTopRecruitment] = useState([]);
    const [loading, setLoading] = useState(true);
    const [accountBalance, setAccountBalance] = useState("0");
    const [dailyprofit, setDailyprofit] = useState("0");
    const [currentCredits, setCurrentCredits] = useState("0");
    const [coins, setCoins] = useState("0");
    const [referral, setReferral] = useState("");
    const [firstname, setFirstname] = useState("");
    const [userName, setUserName] = useState(""); // For the user's name
    const [ismember, setIsmember] = useState(false);

    const [rank, setRank] = useState("Unranked");
    useEffect(() => {
        const fetchDataUser = async () => {
            const user = JSON.parse(Memory.getItem("userinfo"));
            setReferral(user.referral);
            setFirstname(user.first_name);

            setRank(user?.rank?.title || "Unranked");
            setUserName(user.username); // Fetch the username
            setIsmember(user.ismember);
        };
        fetchDataUser();
    }, []);

    // Fetch data from the "user/home" endpoint using RequestEngine
    const fetchData = async () => {
        let engine = new RequestEngine();
        try {
            const response = await engine.getItem("user/home");
            if (response && response.status === 200 && response.data.data) {
                const overview = response.data.data.overview;

                if (overview) {
                    setAccountBalance(overview.accountBalance);
                    setDailyprofit(overview.dailyprofit);
                    setCurrentCredits(overview.currentCredits);
                    setCoins(overview.coins);
                }

                setHistory(response.data.data.history);
                setTopRank(response.data.data.topRank)
                setTopRecruitment(response.data.data.topRecruitment)
                setTopCoins(response.data.data.topCoins)
                setCommitions(response.data.data.commitions);
            } else {
                console.error("Failed to fetch data:", response.data.message);
            }
        } catch (error) {
            console.error("Error while fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    // Get referral count by month for the past 12 months

    useEffect(() => {
        Utilites.setTimeout(() => {
            fetchData();
        }, 1000);
    }, []);



    return (
        <Boxcontent>
            {referral.length === 2 && (
                <GlowingRedText>Please enter your referral code first</GlowingRedText>
            )}

            <HeroSection>
                <h1>You have a Dream, we have the Guide !</h1>
                <p>
                    Welcome <GlowingGreenText>{userName}</GlowingGreenText><br/>
                    your current rank is <GlowingGreenText>{rank}</GlowingGreenText>.
                </p>
            </HeroSection>

            <TopBar commitions={commitions} history={history} ismember={ismember} coins={coins} topCoins={topCoins} topRank={topRank} topRecruitment={topRecruitment} accountBalance={accountBalance} dailyprofit={dailyprofit} currentCredits={currentCredits} />

            </Boxcontent>
    );
};

export default Dashboard;
