import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import locked from "../../assets/images/locked.png";
import bgrank from "../../assets/images/bgrank.png";
import bgrankblurred from "../../assets/images/bgrankblurred.png";
import bgranknext from "../../assets/images/bgranknext.png";
import RequestEngine from "../../core/RequestEngine";
import { Memory } from "../../core/Memory";
import VimeoPlayer from "../course/VimeoPlayer";
import logo from "../../assets/images/logo.svg";
import {useNavigate} from "react-router-dom";
import {Utilites} from "../../core/Utilites";

const RanksContainer = styled.div`
    background-color: #000;
    min-height: 100vh;
    padding: 20px;
    color: white;
`;
const PopularCoursesTitle = styled.h2`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 20px;
    color: #21ad58;
`;

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const Loader = styled.div`
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #21ad58;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: ${spin} 1s linear infinite;
    margin: 100px auto;
`;

const ActivityContainer = styled.div`
    min-height: 100vh;
    padding: 20px;
    color: white;
    margin-top: 30px;
    font-family: "Inter", sans-serif;
`;

const RankGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    background: #0f211c;
    border-radius: 20px;
    padding: 20px;
`;

const RankCard = styled.div`
    background: #000000;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    border: 1px solid #1c2926;
    transition: transform 0.3s;
    cursor: pointer;

    &:hover {
        transform: scale(1.05);
    }
`;

const fillAnimation = keyframes`
  from {
    width: 0%;
  }
  to {
    width: 0%;
  }
`;

const ProgressFill = styled.div`
    height: 100%;
    background-color: #21ad58;
    width: ${({ width }) => width || "0%"}; /* Dynamically set width from props, default to 0% */
    animation: ${fillAnimation} 1s ease-out;
`;

const ProgressText = styled.div`
  margin-top: 8px;
  font-size: 14px;
    margin-right: 10px;
  color: white;
  text-align: right;
    margin-bottom: 10px;
`;
const ProgressBarContainer = styled.div`
  margin: 5px 0;
  width: 100%;
  background-color: #0f211c;
  border-radius: 20px;
  overflow: hidden;
  height: 5px;
`;
const RankCardHeader = styled.div`
    position: relative;
    width: 95%;
    height: 193px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${(props) => props.bgImage || ""});
    background-size: cover;
    background-position: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    overflow: hidden;
    padding: 30px;
`;


const RankImage = styled.img`
    width: 160px;
    height: 160px;
    object-fit: contain;
    margin-bottom: 20px;
    border: 1px solid #2a3a35;
    border-radius: 50%;
    background-color: #1c2926;
`;

const RankDetails = styled.div`
    text-align: center;
    width: 100%;
    background-color: #000000;
    padding: 10px;
`;

const RankTitle = styled.h3`
    font-size: 26px;
    font-weight: bold;
    color: #e6e6e6;
    margin: 0px 0 0;
    text-align: left;
    padding: 10px;
    text-transform: capitalize;
    padding-bottom: 0;
`;



const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    color: #cccccc;
    padding: 10px;
    background: #000;
`;

const FooterItem = styled.span`
    font-weight: bold;
    color: white;
    padding: 10px;
`;

const LightboxOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;

const VideoContainer = styled.div`
    background-color: #000;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 800px;
    text-align: center;
    iframe {
        width: 100%;
        height: 400px;
        border: none;

        @media (max-width: 768px) {
            height: 300px;
        }
    }

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 24px;
        color: white;
        cursor: pointer;
    }
`;

const MissionScreen = () => {
    const [ranks, setRanks] = useState([]); // State to store the rank data
    const [loading, setLoading] = useState(true); // Loading state
    const [lightbox, setLightbox] = useState(false); // Lightbox state
    const [videoUrl, setVideoUrl] = useState(""); // Video URL state
    const [currentorder, setCurrentorder] = useState(0);
    const [rankadded, setRankadded] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            const user = JSON.parse(Memory.getItem("userinfo"));
            setCurrentorder(user?.rank?.orderx || 0);
            setRankadded(user?.rankadded || [])
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchRanks = async () => {
            let engine = new RequestEngine();
            try {
                const response = await engine.getItem("user/days");
                if (response && response.status === 200 && response.data) {

                    let _data = response.data.data
                    setRanks(_data); // Store the fetched rank data
                } else {
                    console.error("Failed to fetch ranks:", response.data.message);
                }
            } catch (error) {
                console.error("An error occurred while fetching ranks:", error);
            } finally {
                setLoading(false); // Stop the loading spinner
            }
        };

        fetchRanks(); // Call the function to fetch rank data
    }, []);

    if (loading) {
        return (
            <ActivityContainer>
                <Loader />
            </ActivityContainer>
        );
    }


    const handleCardClick = (missionId,date) => {
        // Check if the date of the clicked mission is before today
        if(date){
            const today = new Date();
            const missionDate = new Date(date);

            if (missionDate > today) {
                Utilites.showErrorMessage("You cannot access this mission because its date is in the future.");
                return;
            }
        }

        navigate("/missiondetails/"+missionId);
    };

    const closeLightbox = () => {
        setLightbox(false); // Close the lightbox
        setVideoUrl(""); // Clear the video URL
    };

    let inprogress = -1;
    return (
        <RanksContainer>
            <h2 id="title">Missions</h2>

            <PopularCoursesTitle>Week 1</PopularCoursesTitle>
            <RankGrid>
                {ranks.map((rank, index) => {
                    let bgimage = bgrankblurred;
                    let status = rank.status=="open"?"open":"Closed";
                    if (rank.orderx <= currentorder) {
                        status = "Passed";
                        bgimage = bgrank;
                    } else if (inprogress === -1 && rank.orderx > currentorder) {
                        status = "Loading...";
                        bgimage = bgranknext;
                        inprogress = index;
                    }


                    return (
                        <RankCard key={index}
                                  onClick={rank.status === "open" ? () => handleCardClick(rank._id,rank.date) : null}
                                  style={{
                                      cursor: rank.status === "open" ? "pointer !important" : "default", // Change cursor style based on status
                                      opacity: rank.status === "open" ? 1 : 0.6, // Dim non-clickable cards
                                  }}
                        >
                            <RankCardHeader bgImage={bgimage}>
                                <RankImage src={rank.status !== "open"? locked : logo} alt={rank.title} />
                            </RankCardHeader>

                            {status !== "Closed"?<div style={{width:"100%",height: 44}}>
                                <ProgressBarContainer>
                                <ProgressFill width={rank.progress.toString()+"%"} />
                                </ProgressBarContainer>
                          <ProgressText>{rank.progress}% progress</ProgressText>
                            </div>
                           :<div/>}


                            <RankDetails>
                                <RankTitle>{rank.title}</RankTitle>
                            </RankDetails>
                            <Footer>
                                <FooterItem></FooterItem>
                                <FooterItem>{rank.coin} Coin</FooterItem>
                            </Footer>
                        </RankCard>
                    );
                })}
            </RankGrid>

            {lightbox && (
                <LightboxOverlay onClick={closeLightbox}>
                    <VideoContainer onClick={(e) => e.stopPropagation()}>
                        <span className="close" onClick={closeLightbox}>
                            &times;
                        </span>
                        <div>
                            <VimeoPlayer url={videoUrl} />
                        </div>

                    </VideoContainer>
                </LightboxOverlay>
            )}
        </RanksContainer>
    );
};

export default MissionScreen;
