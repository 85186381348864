import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import faqImage from "../../../assets/images/faqImage.png"; // Replace with your image path

// Animations
const slideDown = keyframes`
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 500px;
    opacity: 1;
  }
`;

const slideUp = keyframes`
  from {
    max-height: 500px;
    opacity: 1;
  }
  to {
    max-height: 0;
    opacity: 0;
  }
`;

// Styled Components
const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  padding: 50px 20px;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 90%;
    padding: 40px 10px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 20px;
  color: #fff;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px 10px;
  }
`;

const TextSection = styled.div`
  flex: 1.5;
  background-color: #0d221c;
  border-radius: 22px;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 15px;
    width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 28px;
    text-align: center;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

const Accordion = styled.div`
  margin: 20px 0;
  border: 1px solid #22ad58;
  border-radius: 10px;
  overflow: hidden;
  background-color: #00150f;
`;

const AccordionHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #22ad58;
  transition: background-color 0.3s;

  &:hover {
    background-color: #003d29;
  }

  span {
    font-size: 24px;
    transition: transform 0.3s;
    transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const AccordionBody = styled.div`
  font-size: 16px;
  line-height: 1.6;
  color: #b3b3b3;
  padding: 15px;
  animation: ${({ isOpen }) => (isOpen ? slideDown : slideUp)} 0.5s ease-out;
  max-height: ${({ isOpen }) => (isOpen ? "500px" : "0")};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  overflow: hidden;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 1.4;
  }
`;

const ImageSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);

    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }
`;

// Main Component
const FAQ = () => {
  const [openIndexes, setOpenIndexes] = useState([0, 1]); // First two questions open by default

  const toggleAccordion = (index) => {
    setOpenIndexes((prevIndexes) =>
        prevIndexes.includes(index)
            ? prevIndexes.filter((i) => i !== index)
            : [...prevIndexes, index]
    );
  };

  const faqData = [
    {
      question: "What Is Multyncome?",
      answer:
          "Multyncome is a platform offering AI-driven investment solutions, educational resources for online income, and an affiliate program to help users build reliable income streams.",
    },
    {
      question: "How Does The AI Trading System Work?",
      answer:
          "Our AI-powered trading robots analyze markets 24/7, making data-driven trading decisions on your behalf. You choose your investment level and risk preference, and the AI handles the rest to maximize returns.",
    },
    {
      question: "Who Manages The Investment Funds?",
      answer:
          "Our AI-powered trading robots analyze markets 24/7, making data-driven trading decisions on your behalf. You choose your investment level and risk preference, and the AI handles the rest to maximize returns.",
    },
    {
      question: "What Are The Different Investment Plans And Fees?",
      answer:
          "We offer three flexible investment plans with fees based on investment amounts:\n• $100 - $2,500: 50% Fee on Profits\n• $2,500 - $5,000: 45% Fee on Profits",
    },
  ];

  return (
      <BoxContent>
        <Title>Frequently Asked Questions</Title>
        <Content>
          <TextSection>
            {faqData.map((faq, index) => (
                <Accordion key={index}>
                  <AccordionHeader
                      onClick={() => toggleAccordion(index)}
                      isOpen={openIndexes.includes(index)}
                  >
                    {faq.question}
                    <span>{openIndexes.includes(index) ? "−" : "+"}</span>
                  </AccordionHeader>
                  <AccordionBody isOpen={openIndexes.includes(index)}>
                    {faq.answer}
                  </AccordionBody>
                </Accordion>
            ))}
          </TextSection>
          <ImageSection>
            <img src={faqImage} alt="FAQ Illustration" />
          </ImageSection>
        </Content>
      </BoxContent>
  );
};

export default FAQ;
