import React from "react";
import styled from "styled-components";
import { FaStar, FaRegCircle } from "react-icons/fa";

const RankContainer = styled.div`
    background-color: #0f211c;
    border-radius: 20px;
    padding: 20px;
    color: white;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px;
    margin: 20px auto;
    font-family: "Inter", sans-serif;
`;

const RankHeader = styled.h3`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
`;

const RankItem = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    gap: 15px;
`;

const IconContainer = styled.div`
    background-color: #1f392e;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #21ad58;
    font-size: 24px;
    width: 50px;
    height: 50px;
`;

const Description = styled.p`
    font-size: 14px;
    color: #d1d1d1;
    line-height: 1.6;
`;

const RankSection = () => {
    return (
        <RankContainer>
            <RankHeader>Rank</RankHeader>
            <RankItem>
                <IconContainer>
                    <FaStar />
                </IconContainer>
                <Description>
                    To achieve a specific rank, users need to accumulate the needed  credits for that rank. Additionally, they must maintain a minimum balance of resources, such as 100 points or tokens, to qualify for the rank upgrade.
                </Description>
            </RankItem>
        </RankContainer>
    );
};

export default RankSection;
