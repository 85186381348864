import React from "react";
import styled from "styled-components";
import videoSrc from "../../../assets/images/intro.mp4"; // Update with the actual path to your video file

const Goal = styled.p`
    font-size: 18px;
    font-weight: bold;
    color: #b3b3b3;
    margin-top: 20px;

    @media (max-width: 768px) {
        font-size: 16px;
        margin-top: 10px;
    }
`;

const StatNumber = styled.span`
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    display: block;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

const StatText = styled.span`
    font-size: 14px;
    color: #b3b3b3;

    @media (max-width: 768px) {
        font-size: 12px;
    }
`;

const TextSection = styled.div`
    grid-column: 1;
    text-align: center;
`;

const Content = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;

    @media (max-width: 768px) {
        padding: 15px;
    }
`;

const VideoSection = styled.div`
    width: 100%;
    text-align: center;

    video {
        max-width: 100%;
        height: auto;
        margin: 0 auto;
        border-radius: 10px;

        @media (max-width: 768px) {
            max-width: 90%;
        }

        @media (max-width: 480px) {
            max-width: 100%;
        }
    }
`;

const StatsSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: -100px;

    @media (max-width: 768px) {
        flex-direction: column;
        margin-top: -50px;
    }

    @media (max-width: 480px) {
        margin-top: -30px;
    }
`;

const Stat = styled.div`
    background: #0D221C;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 20px;
    width: 200px;
    text-align: left;
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
        box-shadow: 0 0 10px rgba(50, 205, 50, 0.7); /* Optional glow effect */
    }

    @media (max-width: 768px) {
        width: 80%;
        text-align: center;
        padding: 15px;
    }
`;

const Section1 = () => {
    return (
        <Content>
            <VideoSection>
                <video src={videoSrc} autoPlay muted playsInline loop={false} />
            </VideoSection>
            <StatsSection>
                <Stat>
                    <StatNumber>5K+</StatNumber>
                    <StatText>Satisfied Customers</StatText>
                </Stat>
                <Stat>
                    <StatNumber>5+ Years</StatNumber>
                    <StatText>Trading Experience</StatText>
                </Stat>
                <Stat>
                    <StatNumber>24/7</StatNumber>
                    <StatText>Online Support</StatText>
                </Stat>
                <Goal>
                    Goal: Experience smart investing and lay the foundation for financial growth.
                </Goal>
            </StatsSection>
        </Content>
    );
};

export default Section1;
