import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes, css } from "styled-components";
import step1 from "../../../assets/images/step1.jpg";
import step2 from "../../../assets/images/step2.jpg";
import step3 from "../../../assets/images/step3.jpg";

// Keyframe Animations
const fadeInUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

// Styled Components
const SectionWrapper = styled.div`
    background-color: #0B261C;
    padding: 0px 20px;
    text-align: center;
`;

const Title = styled.h1`
    color: #fff;
    font-size: 56px;
    font-weight: 700;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 0;

    ${({ isVisible }) =>
            isVisible &&
            css`
                animation: ${fadeInUp} 1s ease-out;
            `}

    @media (max-width: 768px) {
    font-size: 40px;
}

    @media (max-width: 480px) {
        font-size: 32px;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10vmin;
    overflow: hidden;
    height: 400px;
    transform: skew(5deg);

    @media (max-width: 768px) {
        flex-direction: column;
        transform: none;
        margin: 10vmin 0;
        height: auto;
    }
`;

const Card = styled.div`
    flex: 1;
    transition: all 1s ease-in-out;
    height: 55vmin;
    position: relative;
    overflow: hidden;

    &:hover {
        flex-grow: 10;

        img {
            filter: grayscale(0);
        }

        .card__head {
            text-align: center;
            top: calc(100% - 5em);
            color: white;
            background: rgba(0, 0, 0, 0.5);
            font-size: 2em;
            transform: rotate(0deg) skew(-5deg);
            @media (max-width: 768px) {
            display: none;
            }
        }

        .step-description {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &:not(:nth-child(5)) {
        margin-right: 1em;

        @media (max-width: 768px) {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 1s ease-in-out;
        filter: grayscale(100%);
    }

    .card__head {
        color: white;
        background: black;
        padding: 0.5em;
        transform: rotate(-90deg);
        transform-origin: 0% 0%;
        transition: all 0.5s ease-in-out;
        min-width: 100%;
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 1em;
        white-space: nowrap;
        @media (max-width: 768px) {
           display: none;
        }
    }
`;

const StepDescription = styled.div`
    font-size: 16px;
    color: #b3b3b3;
    line-height: 1.5;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    position: absolute;
    bottom: 50px;
    left: 0;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    padding: 10px;

    @media (max-width: 768px) {
        bottom: 10px;
        font-size: 13px;
    }

    @media (max-width: 480px) {
        bottom: 20px;
        font-size: 12px;
    }
`;

// Main Component
const Section2 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.5 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <SectionWrapper id="affiliate" ref={sectionRef}>
            <Title isVisible={isVisible}>Grow and Succeed Together</Title>

            <Container>
                <Card>
                    <img src={step1} alt="Step 1" />
                    <div className="card__head">Step 1 - Begin Your Journey</div>
                    <StepDescription className="step-description">
                        Begin your journey with AI-powered investments to achieve impressive
                        returns effortlessly.
                    </StepDescription>
                </Card>
                <Card>
                    <img src={step2} alt="Step 2" />
                    <div className="card__head">Step 2 - Join the Affiliate Program</div>
                    <StepDescription className="step-description">
                        Once you’re comfortable with the system, join the affiliate program and
                        share opportunities with others.
                    </StepDescription>
                </Card>
                <Card>
                    <img src={step3} alt="Step 3" />
                    <div className="card__head">Step 3 - Achieve Higher Ranks</div>
                    <StepDescription className="step-description">
                        Continue growing within the platform, achieving higher ranks through
                        consistent activity and successful referrals.
                    </StepDescription>
                </Card>
            </Container>
        </SectionWrapper>
    );
};

export default Section2;
